<template>
  <div role="tablist">

    <div
      class="accordion__item"
      :class="{
        'visible': visible[index] && opened,
        'not-visible': !visible[index] && opened
      }"
      v-for="(item, key, index) in items"
      :key="key"
    >


      <div
        class="accordion__heading no-select justify-content-center"
        role="tab"
        v-b-toggle="key"
        variant="primary"
      >
        <span>{{ item.title }}</span>
        <small
          class="text-white bg-success"
          v-if="subtotal(key, item.inputs)"
        >
          {{ subtotal(key, item.inputs) | currency }}
        </small>
      </div>

      <b-collapse
        role="tabpanel"
        :id="key"
        :visible="index === 0"
        v-model="visible[index]"
        class="mt-2"
        accordion="Accordion"
      >

        <div
          class="accordion__body"
          v-for="(title, _key) in item.inputs"
          :key="_key"
        >
          
          <form-group
            class="step-form__item d-flex align-items-center justify-content-between"
            :label="title"
            :labelId="_key"
          >
          
            <currency-input
              required 
              :value-range="{min: 0, max: 9999999}"              
              :allow-negative="false"
              class="form-control step-form__input"
              :id="_key"
              :name="_key"
              :value="data[section][key+'.'+_key] ? data[section][key+'.'+_key] : 0"
              @input="setData(key+'.'+_key, $event)"
            ></currency-input>

            <div class="step-form__input-helper">
              (£ per month)
            </div>

          </form-group>

        </div>

      </b-collapse>

    </div>
  </div>
</template>

<script>

  import { mapState } from 'vuex'

  export default {

    computed: {
      ...mapState({
        data: state => state.plan.data,
      }),
      opened() {
        // Are there any 'true' values in the this.visible data array?
        return this.visible.filter(x => x === true).length
      }
    },

    created() {

      // Important - if there are entries in the data which aren't
      // present in the default field list (e.g. if added, and then a field
      // has been removed), add them in so that the total isn't off
      Object.entries(this.data[this.section]).forEach(
        (item) => {
          let key = item[0].split('.')
          let value = item[1]
          let title = this.humanizeCamel(key[1])
          
          if(this.items[key[0]].inputs[key[1]] == undefined) {
            this.items[key[0]].inputs[key[1]] = title
          }
        }
      )

      console.log(this.items)
    },

    data() {
      return {
        visible: []
      }
    },

    methods: {

      setData: function(key, value) {
        // TODO - Why is $event passing the value???? Figure that out plz
        this.$store.commit('SET_DATA', {
          section: this.section,
          key,
          value
        })
      },

      subtotal(section, items) {
        // I'm sure there's a tidier way to do this...
        let total = 0
        Object.keys(items).forEach((key) => {
          let v = parseFloat(this.$store.state.plan.data[this.section][section+'.'+key])
          if(isNaN(v)) {
            v = 0
          }
          total += v
        })
        return total
      }

    },

    props: {
      items: Object,
      section: String
    },
    name: "FinanceInput"
  }
</script>

<style scoped>
  label {
    margin-bottom: 0;
  }
</style>