var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-12 col-md-6 p-3 p-sm-5 rounded bg-light"},[_c('h2',{staticClass:"step-panel__title d-flex justify-content-between"},[_vm._v("Your assets"),(_vm.data.has_assets)?_c('strong',[_vm._v(_vm._s(_vm._f("currency")(_vm.total)))]):_vm._e()]),_c('b-form-group',{attrs:{"label":"Do you have any other assets?"}},[_c('b-form-radio-group',{attrs:{"name":"assets","value":false,"buttons":"","button-variant":"clear","options":{
            yes: {text: 'Yes', value: true},
            no: {text: 'No', value: false},
        }},model:{value:(_vm.hasAssets),callback:function ($$v) {_vm.hasAssets=$$v},expression:"hasAssets"}})],1),(_vm.hasAssets)?_c('div',[_vm._l((_vm.$v.data.assets.$each.$iter),function(asset,index){return _c('div',{key:index,staticClass:"card my-4"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row d-flex justify-content-center align-items-center"},[_c('form-wrapper',{staticClass:"col step-form",attrs:{"validator":asset}},[_c('div',{staticClass:"form-row"},[_c('form-group',{staticClass:"step-form__item col",attrs:{"name":"type","labelId":"type"}},[_c('b-form-select',{staticClass:"form-control step-form__input",attrs:{"value":_vm.data['assets'][index]['type'],"state":_vm.validateState(asset.type),"options":_vm.types},on:{"input":function($event){return _vm.setData(index, 'type', $event)},"select":function($event){return asset.type.$touch()}}})],1),(_vm.data['assets'][index]['type'] == 'investments' || _vm.data['assets'][index]['type'] == 'shares')?_c('form-group',{staticClass:"step-form__item d-flex align-items-center col-12",attrs:{"label":"Name","labelId":"name","name":"name","messages":{
                    required: 'Please enter the name'
                  }}},[_c('b-form-input',{staticClass:"form-control step-form__input",attrs:{"value":_vm.data['assets'][index]['name']},on:{"change":function($event){return _vm.setData(index, 'name', $event)}}})],1):_vm._e(),(_vm.data['assets'][index]['type'] == 'vehicle')?_c('form-group',{staticClass:"step-form__item d-flex align-items-center col-12",attrs:{"label":"Registration","labelId":"registration","name":"registration","messages":{
                    required: 'Please enter the vehicle registration'
                  }}},[_c('b-form-input',{staticClass:"form-control step-form__input",attrs:{"placeholder":"e.g. AA1 1AA","value":_vm.data['assets'][index]['registration']},on:{"change":function($event){return _vm.setData(index, 'registration', $event)}}})],1):_vm._e(),(_vm.data['assets'][index]['type'] == 'vehicle')?_c('form-group',{staticClass:"step-form__item d-flex align-items-center col-12",attrs:{"label":"Mileage (miles)","labelId":"mileage","name":"mileage","messages":{
                    required: 'Please enter the vehicle mileage'
                  }}},[_c('b-form-input',{staticClass:"form-control step-form__input",attrs:{"placeholder":"e.g. 35,000","value":_vm.data['assets'][index]['mileage']},on:{"change":function($event){return _vm.setData(index, 'mileage', $event)}}})],1):_vm._e(),(_vm.data['assets'][index]['type'] == 'vehicle')?_c('form-group',{staticClass:"step-form__item d-flex align-items-center col-12",attrs:{"label":"Make","labelId":"make","name":"make","messages":{
                    required: 'Please enter the vehicle make'
                  }}},[_c('b-form-input',{staticClass:"form-control step-form__input",attrs:{"placeholder":"e.g. Ford","value":_vm.data['assets'][index]['mileage']},on:{"change":function($event){return _vm.setData(index, 'mileage', $event)}}})],1):_vm._e(),(_vm.data['assets'][index]['type'] == 'property')?_c('form-group',{staticClass:"step-form__item d-flex align-items-center col-12",attrs:{"label":"Property type","labelId":"property_type","name":"property_type","messages":{
                    required: 'Please select the property type'
                  }}},[_c('b-form-select',{staticClass:"form-control step-form__input",attrs:{"value":_vm.data['assets'][index]['property_type'],"options":_vm.propertyTypes},on:{"change":function($event){return _vm.setData(index, 'property_type', $event)}}})],1):_vm._e(),_c('form-group',{staticClass:"step-form__item d-flex align-items-center col-12",attrs:{"label":"Asset value","name":"value","labelId":"value","messages":{
                    required: 'Please enter an asset value',
                    minValue: 'Please enter an asset value'
                  }}},[_c('currency-input',{staticClass:"form-control step-form__input",attrs:{"value":_vm.data['assets'][index]['value'],"state":_vm.validateState(asset.value)},on:{"input":function($event){return _vm.setData(index, 'value', $event)},"blur":function($event){return asset.value.$touch()}}})],1),_c('div',{staticClass:"col text-right"},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.removeAsset(_vm.key)}}},[_c('i',{staticClass:"far fa-trash-alt error"}),_vm._v(" Delete asset")])])],1)])],1)])])}),_c('div',{staticClass:"mt-4"},[_c('p',[_c('a',{staticClass:"text-decoration-none",attrs:{"href":"javascript:void(0)","size":"sm"},on:{"click":_vm.addAsset}},[_c('i',{staticClass:"far fa-plus"}),_vm._v(" "+_vm._s(_vm.addMoreButtonText))])])])],2):_vm._e(),_c('navigation',{staticClass:"mt-4",attrs:{"disableNext":_vm.$v.$invalid}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 col-md-6 rounded p-3 p-sm-5 text-light"},[_c('h1',{staticClass:"main-title mb-3 mb-sm-5"},[_vm._v("Please tell us here if you have any assets")]),_c('p',[_vm._v("Assets can be things like:")]),_c('ul',{staticClass:"check-list mb-3 mb-sm-5"},[_c('li',{staticClass:"check-list__item"},[_c('i',{staticClass:"fas fa-check-circle"}),_vm._v("Shares")]),_c('li',{staticClass:"check-list__item"},[_c('i',{staticClass:"fas fa-check-circle"}),_vm._v("Investments")]),_c('li',{staticClass:"check-list__item"},[_c('i',{staticClass:"fas fa-check-circle"}),_vm._v("Savings")]),_c('li',{staticClass:"check-list__item"},[_c('i',{staticClass:"fas fa-check-circle"}),_vm._v("Property")]),_c('li',{staticClass:"check-list__item"},[_c('i',{staticClass:"fas fa-check-circle"}),_vm._v("Vehicles you own")])])])}]

export { render, staticRenderFns }