<template>
  <div class="row">
    <div class="col py-5 text-light">
        <h1 class="main-title mb-5">Forgotten your password?</h1>
        <p>Use this form to reset your password. Enter your email address. If that email is registered with us, you will recieve a confirmation email with further instructions.</p>
        
        <p><router-link to="/signin">Click here</router-link> to sign in</p>
    </div>    
    <div class="col p-5 rounded bg-light">
      <form-wrapper :validator="$v.form" class="col p-5 rounded bg-light step-form">

        <b-form
          action="#"
          @submit.prevent="submit"
          v-if="!sent"
        >

          <form-group
            class="step-form__item"
            label="Email Address"
            name="email"
            :messages="{
              email: 'Please provide a valid email address',
              required: 'Enter your email address'
            }"
          >

            <b-form-input
              class="form-control step-form__input"
              type="text"
              name="email"
              id="email"
              v-model="form.email"
              @blur="$v.form.email.$touch()"
              :state="validateState('email')"
            />

          </form-group>

          <b-btn-toolbar>
            <button
              :disabled="$v.$invalid"
              class="btn btn-primary"
              :class="{ loading }"
              type="submit"
            >
              Send request
              <b-spinner
                v-if="loading"
                class="ml-2"
                small
                label="Spinning"
              ></b-spinner>
            </button>
          </b-btn-toolbar>

        </b-form>

        <div v-else>
          <p>Thanks. We've sent a password reset link to you via email</p>
          <p>Check your email, if nothing shows up after a while, check your spam folder.</p>
        </div>

      </form-wrapper>

    </div>
  </div>
</template>

<script>
  //import axios from 'axios'
  import { mapActions } from 'vuex'
  import { validationMixin } from "vuelidate";
  import { helpers, required, email, minLength } from 'vuelidate/lib/validators'

  export default {
    name: 'SignIn',

    mixins: [validationMixin],

    data () {
      return {
        sent: false,
        loading: false,
        form: {
          email: 'test@email.com',
        }
      }
    },

    methods: {
      ...mapActions({
        requestPasswordReset: 'auth/requestPasswordReset'
      }),

      // Export to global mixins?
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },

      async submit () {
        this.$v.$touch()        
        if(!this.$v.$invalid) {
          try {
            this.loading = true
            await this.requestPasswordReset(this.form.email)
            this.sent = true
            this.$toasted.success("We have sent a password reset link to your email address")
          } catch(error) {
            this.$toasted.error(error.response.data.errors.email)
          }
          this.loading = false
        } else {
          return false
        }
      }
    },

    validations: {
        
        form: {
            email: {
                required,
                email
            }
        }
    }

  }
</script>