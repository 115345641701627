<template>
  <div class="row justify-content-center">
    <div class="col col-lg-6 p-3 p-sm-5 m-3 rounded step-panel num-verify__step">


      <div class="d-flex flex-column align-items-center text-center" v-if="!verified">

        <h2 class="mb-5">Hi {{ user.name }} <br />We’ve just sent a text to your phone number (ending in ...{{ user.phone_number }}). Please enter below to verify your details</h2>

        <b-form-group
          class="num-verify mb-4"
        >
            <CodeInput
              :verifying="false"
              class="input"
              :fieldHeight="100"
              :autoFocus="true"
              v-on:change="onChange"
              v-on:complete="onComplete"
            />

        </b-form-group>

        <div class="num-verify__link">
          <i class="fal fa-redo"></i>
          <a
            @click="resend"
            href='#'
          >Resend code</a>
          <b-spinner
            v-if="resending"
            class="ml-2"
            small
            label="Spinning"
          ></b-spinner>
        </div>

        <button
          :disabled="status != 'DONE'"
          type="submit"
          @click="submit"
          class="btn btn-primary mt-4"
          :class="{ verifying }"
        >

          Verify

          <b-spinner
            v-if="verifying"
            class="ml-2"
            small
            label="Spinning"
          ></b-spinner>

        </button>

        <div class="py-3" v-if="message">{{ message }}</div>

        <div class="py-3"><small>(If this is the dev server, you can use 111111 to quickly verify - this won't work on live)</small></div>

      </div>

      <div class="d-flex flex-column align-items-center text-center" v-else>
        
        <h2 class="mb-5">Thank you!</h2>
        <p>Your phone number has been verified</p>

      </div>

    </div>      
  </div>
</template>

<script>
  //import axios from 'axios'
  import { mapActions, mapGetters } from 'vuex'
  import CodeInput from "vue-verification-code-input"
  import Vue from 'vue'

  export default {
    name: 'RegisterSMS',

    components: {
      CodeInput
    },

    computed: {
      ...mapGetters({
        authenticated: 'auth/authenticated',
        verified: 'auth/verified', 
        user: 'auth/user'
      })
    },

    data () {
      return {
        // todo link with vuex store
        token: '',
        status: null,
        confirmed: false,
        verifying: false,
        resending: false,
        message: false
      }
    },

    methods: {
      onChange(v) {
        this.token = v
        this.status = "PROGRESS"
      },
      onComplete(v) {
        this.status="DONE"
      },
      resend() {
        this.resending = true
        this.$store.dispatch('auth/startPhone2fa').then(
          (response) => {
            // Success, 2xx
            this.$toasted.success("A code has been sent to your phone")
          },
          (error) => {
            // fail 4xx
            this.$toasted.error(error.response.data.message)
          }
        ).then(_ => {
          this.resending = false
        })
      },
      submit() {
        this.verifying = true
        this.$store.dispatch('auth/verifyPhone2fa', this.token)
          .then(
            (response) => {
              this.$toasted.success("Thanks! You have been successfully verified")
            },
            (error) => {
              this.$toasted.error(error.response.data.message)
            }
          )
          .then(_ => {
            this.verifying = false
          })
      }
    }

  }
</script>