<template>
  <div class="row">
    <div class="col-12 col-sm rounded p-3 p-sm-5 text-light">
      <h1 class="main-title mb-3 mb-sm-5">Finally, we are going to ask you about your debts</h1>
      <p>Debts can fall under many categories such as:</p>

      <ul class="check-list mb-3 mb-sm-5">
        <li class="check-list__item"><i class="fas fa-check-circle"></i>Tax bills</li>
        <li class="check-list__item"><i class="fas fa-check-circle"></i>Credit cards</li>
        <li class="check-list__item"><i class="fas fa-check-circle"></i>Personal loans</li>
        <li class="check-list__item"><i class="fas fa-check-circle"></i>Utilities</li>
        <li class="check-list__item"><i class="fas fa-check-circle"></i>Appliance rental</li>
      </ul>

      <p>Please take your time to think about who and how much money you owe, this will be essential for us to offer which help is available to you.</p>

    </div>


    <div class="col-12 col-sm p-3 p-sm-5 rounded bg-light">
      
      <h2 class="step-panel__title d-flex justify-content-between">Debts<strong>{{ total | currency }}</strong></h2>
      
      <div class="card my-4" v-for="(debt, index) in $v.data.debts.$each.$iter" :key='index'>
        <div class="card-body">

          <form-wrapper :validator="debt" class="step-form">

            <div class="row">

              <form-group
                name="type"
                labelId="type"
                class="step-form__item col-12"
              >
                <b-form-select
                  :value="data['debts'][index]['type']"
                  class="form-control step-form__input"
                  @input="setData(index, 'type', $event)"
                  :options="types"
                ></b-form-select>
              </form-group>

              <form-group
                label="Company name"
                labelId="company"
                name="company"
                class="step-form__item d-flex align-items-center col-12"
              >
                <vue-bootstrap-typeahead
                  inputClass="step-form__input form-control"
                  :value="data['debts'][index]['company']"
                  @input="setData(index, 'company', $event)"
                  @blur="debt.company.$touch()"
                  :data="['Sainsburys', 'Argos', 'Bank', 'Test', 'Company 1', 'Company 2', 'Company 3']"
                  :state="validateState(debt.company)"                    
                ></vue-bootstrap-typeahead>
              </form-group>

              <form-group
                label="Amount owed"
                name="value"
                labelId="type"
                class="step-form__item d-flex align-items-center col-12"
              >
                <currency-input
                  class="form-control step-form__input"
                  :value="data['debts'][index]['value']"
                  @input="setData(index, 'value', $event)"
                  @blur="debt.value.$touch()"
                  :state="validateState(debt.value)"                    
                ></currency-input>
              </form-group>

              <div class="col text-right" v-if="index != 0">
                <a href="javascript:void(0)" class="text-decoration-none" @click="  removeDebt(index)"><i class="far fa-trash-alt error"></i> Delete debt</a>
              </div> 

            </div>            

          </form-wrapper>
        </div>
      </div>

        
      <div class="mt-4">
          <p><a @click="addDebt" href="javascript:void(0)" size="sm" class="text-decoration-none"><i class="far fa-plus"></i> {{ addMoreButtonText }}</a></p>
      </div>


      <navigation class="mt-4" v-bind:disableNext="$v.$invalid"></navigation>
      
    </div>
  </div>
</template>

<script>

  import Navigation from './Navigation'
  import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

  import { mapGetters, mapMutations, mapState } from 'vuex'


  import { validationMixin } from "vuelidate"
  import { helpers, required, email, minLength, minValue } from 'vuelidate/lib/validators'    

  export default {

    components: { Navigation, VueBootstrapTypeahead },

    computed: {

      addMoreButtonText() {
        switch(this.data.debts.length) {
          case 0:
            return 'Add your first debt'
          case 1:
          default:
            return 'Add another debt'
        }
      },

      ...mapState({
        data: state => state.plan.data,
      }),

      total: function() {
        return this.$store.getters.totalDebts
        // sum all 'expenses' values in the vuex store
        //return Object.values(this.data['income']).reduce((p, c) => p + c)
      },            
    },

    methods: {
      addDebt() {
        this.$store.commit('ADD_DEBT')
      },
      removeDebt(index) {
        this.$store.commit('REMOVE_DEBT', {index})
      },
      // todo - remove the DRY across components...
      setData: function(index, key, value) {
        // TODO - Why is $event passing the value???? Figure that out plz
        this.$store.commit('SET_INDEXED_ARRAY_DATA', {
          section: 'debts',
          index,
          key,
          value
        })
      },
      validateState(field) {
        const { $dirty, $error } = field;
        return $dirty ? !$error : null;
      }
    },

    mounted() {
      // Make sure there's always one box to begin with
      if(this.data.debts.length == 0) {
        this.addDebt()
      }
    },


    data () {
      return {
      types: [
        { value: null, text: 'Select type of debt'},
        { value: 'credit', text: 'Credit card'},
        { value: 'loan', text: 'Personal loan'},
        { value: 'rentArrears', text: 'Rent Arrears'},
        { value: 'utilities', text: 'Utilities'},
        { value: 'hmrcTax', text: 'HMRC Tax'},
        { value: 'creditCard', text: 'Credit card'},
        { value: 'personalLoan', text: 'Personal loan'},
        { value: 'overdraft', text: 'Overdraft'},
        { value: 'catalogue', text: 'Catalogue'},
        { value: 'chargeCard', text: 'Charge card'},
        { value: 'applianceRental', text: 'Appliance rental'},
        { value: 'insurance', text: 'Insurance'},
        { value: 'leasing', text: 'Leasing'},
        { value: 'other', text: 'Other'},
      ],
      debtType: 'debt2',
      debtValue: 0,
      any: null,
      // todo link with vuex store
      amount: 0
      }
    },

    validations() {
      return {
        data: {
          debts: {
            //required,
            //minLength: minLength(1),
            $each: {
            type: {
              //required
            },
            company: {
              //required
            },
            value: {
              //required,
              //minValue: minValue(1)
            }
            }
          }
        }
      }
    }

  }
</script>