<template>
    <div class="row justify-content-center">
        <div class="rounded bg-light d-flex flex-column align-items-center">
            <p>Thanks. Now please create a password to complete your profile</p>
            
            <b-form-group>
                
              <b-form-input
                class="form-control"
                type="password"
                name="password"
                id="password"
                data-lpignore="true"
                v-model="password"
              ></b-form-input>

              <password
                :strength-meter-only="true"
                :secureLength="7"
                @score="setScore"
                v-model="password"
              />

            </b-form-group>

            <div class="password-box">

            <p v-if="!strength">Your password must be at least 7 characters</p>
            <p v-if="strength == 1">Please make your password more secure (try entering a special character like ! or @, or add some more letters/numbers)</p>
            
            <p v-if="strength >= 2">Thanks, your password is suitably strong</p>
            <p v-if="strength >= 2">By submitting... <a>Terms and conditions (popup)</a></p>

            </div>

        </div>
    </div>
</template>

<script>
  //import axios from 'axios'
  import { mapActions } from 'vuex'
  import Password from 'vue-password-strength-meter'

  // To do - move within a 'register' view folder...
  export default {
    name: 'RegisterPassword',
    
    components: { Password },
    
    props: {
      password: String,
      strength: Number
    },

    methods: {
      setScore: function(score) {
        this.strength = score;
      }
    }

  }
</script>

<style scoped>

.password-box{
  min-height: 120px;
}

</style>