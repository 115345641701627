import Home from '../views/Home.vue'
import Account from '../views/Account.vue'
import Register from '../views/Register.vue'
import RegisterPassword from '../views/RegisterPassword.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ResetPassword from '../views/ResetPassword.vue'
import RegisterSMS from '../views/RegisterSMS.vue'
import Results from '../views/Results.vue'
import PlanTests from '../views/PlanTests.vue'
import SignIn from '../views/SignIn.vue'
import Form from '../views/Form.vue'

import Income from '../components/steps/Income.vue'
import FlexibleOutgoings from '../components/steps/FlexibleOutgoings.vue'
import FixedOutgoings from '../components/steps/FixedOutgoings.vue'
import PersonalDetails from '../components/steps/PersonalDetails.vue'
import Assets from '../components/steps/Assets.vue'
import Vehicles from '../components/steps/Vehicles.vue'
import Debts from '../components/steps/Debts.vue'

import NotFound from '../views/NotFound.vue'

export default [
  {
    path: '/',
    redirect: '/register',
    name: 'home',
    component: Home,
    meta: {
      public: true,
    }
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
    meta: {
      redirectIf: {
        unverifiedAuth: 'registerSMS',
        verifiedAuth: 'form'
      }
    }
  },  
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      redirectIf: {
        guest: 'login',
        unverifiedAuth: 'registerSMS'
      }
    }
  },
  {
    path: '/register/forgot',
    name: 'forgot',
    component: ForgotPassword,
    meta: {
      redirectIf: {
        verifiedAuth: 'form',
        unverifiedAuth: 'registerSMS'
      }
    }
  },
  {
    path: '/register/reset/:token',
    name: 'reset',
    component: ResetPassword,
    meta: {
      redirectIf: {
        verifiedAuth: 'form',
        unverifiedAuth: 'registerSMS'
      }
    }
  },
  {
    path: '/register/sms',
    name: 'registerSMS',
    component: RegisterSMS,
    meta: {
      redirectIf: {
        guest: 'login',
        verifiedAuth: 'form',
      }
    }
  },
  {
    path: '/signin',
    name: 'login',
    component: SignIn,
    meta: {
      redirectIf: {
        verifiedAuth: 'form',
        unverifiedAuth: 'registerSMS'
      }
    }
  },
  {
    path: '/form',
    redirect: '/form/income',
    name: 'form',
    component: Form,
    children: [
      { path: 'income', name: 'income', component: Income },
      { path: 'flexible-outgoings', name: 'flexible-outgoings', component: FlexibleOutgoings },
      { path: 'fixed-outgoings', name: 'fixed-outgoings', component: FixedOutgoings },
      { path: 'personal-details', name: 'personal-details', component: PersonalDetails },
      { path: 'assets', name: 'assets', component: Assets },
      { path: 'vehicles', name: 'vehicles', component: Vehicles },
      { path: 'debts', name: 'debts', component: Debts },
    ],
    meta: {
      redirectIf: {
        guest: 'login',
        unverifiedAuth: 'registerSMS'
      }
    }
  },
  {
    path: '/results',
    name: 'results',
    component: Results,
    meta: {      
      redirectIf: {
        guest: 'login',
        unverifiedAuth: 'registerSMS'
      }
    }
  },
  {
    path: '/plantests',
    name: 'plantests',
    component: PlanTests
  },
  {
    path: '*', component: NotFound
  }  
]