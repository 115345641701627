<template>
  <div class="row">
    <div class="col-12 col-md-6 py-3 py-md-5 text-light">
        <h1>Log in with your Creditfix details</h1>
        <p><router-link to="register">Click here</router-link> to create a new account/profile</p>
        <p>For testing, use these credentials:</p>
        <ul>
          <li>Email: js@email.com</li>
          <li>Password: password</li>
        </ul>
    </div>
    <form-wrapper :validator="$v.form" class="col-12 col-md-6 p-3 p-md-5 rounded bg-light step-form">
      
      <b-form action="#" @submit.prevent="submit" >

        <form-group
          class="step-form__item"
          label="Email Address"
          name="email"
          :messages="{
            email: 'Please provide a valid email address',
            required: 'Enter your email address'
          }"
        >

          <b-form-input
            class="form-control step-form__input"
            type="text"
            name="email"
            id="email"
            v-model="form.email"
            @blur="$v.form.email.$touch()"
            :state="validateState('email')"
          />

        </form-group>


        <form-group
          class="step-form__item"
          label="Password"
          name="password"
          :messages="{
            required: 'Please enter your password',
            minLength: 'Your password will be at least 7 characters long',
          }"
        >

          <b-form-input
            class="form-control step-form__input"
            type="password"
            v-model="form.password"
            @blur="$v.form.password.$touch()"
            :state="validateState('password')"
          />        

        </form-group>


        <b-btn-toolbar class="d-flex align-items-center justify-content-between">
          <button
            :disabled="$v.$invalid"
            class="btn btn-primary"
            :class="{ loading }"
            type="submit"
          >
            
            Sign in
            <b-spinner
              v-if="loading"
              class="ml-2"
              small
              label="Spinning"
            ></b-spinner>

          </button>          
          <router-link class="text-decoration-none" to="/register/forgot">Forgotten your password?</router-link>
        </b-btn-toolbar>

      </b-form>


    </form-wrapper>
  </div>
</template>

<script>
  //import axios from 'axios'
  import { mapActions } from 'vuex'
  
  import { helpers, required, email, minLength } from 'vuelidate/lib/validators'

  export default {
    name: 'SignIn',

    data () {
      return {
        form: {
          email: 'test@email.com',
          password: 'password',
        },
        loading: false
      }
    },

    methods: {
      ...mapActions({
        signIn: 'auth/signIn'
      }),

      // Export to global mixins?
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },

      async submit () {
        this.$v.$touch()

        if(!this.$v.$invalid) {
          this.loading = true
          try {
            await this.signIn(this.form)
            this.$toasted.success("Thanks! You're now logged in")
          } catch(error) {
            this.$toasted.error(error.response.data.message)
          }
          this.loading = false
          this.$router.replace({ name: 'form' })
        } else {
          return false
        }
      }
    },

    validations: {
      
      form: {
        password: {
          required,
          minLength: minLength(7)
        },
        email: {
          required,
          email
        }
      }
    }

  }
</script>