import axios from 'axios'
import Vue from 'vue'

const auth = {
  namespaced: true,

  state() {
    return {
      authenticated: false,
      user: {
          email: null,
          password: 'password',
          phone: 123,
          plans: [],
          session_is_verified: 'nope'
      },
      phone2faSent: false,
      phone2faAccepted: false,
      createUser: []
    }
  },

  getters: {

    status (getters, state) {
      if(state.user && state.user.session_is_verified) {
        return 'verifiedAuth'
      } else if(state.authenticated) {
        return 'unverifiedAuth'
      } else {
        return 'guest'
      }
    },

    authenticated (state) {
      return state.authenticated
    },

    verified (getters, state) {
      if(!getters.authenticated) {
        return false
      }
      console.log(state.user.session_is_verified)
      return state.user.session_is_verified ? true : false
    },

    loggedIn (getters) {
      return getters.verified && getters.authenticated
    },

    user (state) {
      return state.user
    }
  },

  mutations: {
    SET_AUTHENTICATED (state, value) {
      state.authenticated = value
    },

    SET_USER (state, value) {
      state.user = value
    },

    SET_CREATE_DATA (state, {key, value}) {
      Vue.set(state.createUser, key, value)
    }
  },

  actions: {
    async signIn ({ dispatch }, credentials) {
      await axios.get('/sanctum/csrf-cookie')
      await axios.post('/login', credentials)
      return dispatch('me')
    },

    async signOut ({ dispatch }) {
      await axios.post('/logout').then(_ => {
        dispatch('clearPlan', null, {root: true})
      })
      return dispatch('me')
    },

    async requestPasswordReset ({ dispatch }, email) {
      await axios.post('/password/email', { email })
    },

    async sendPasswordReset ({ dispatch }, { token, email, password }) {
      console.log(token, email, password)
      await axios.post('/password/reset', {
        token,
        email,
        password,
        password_confirmation: password
      })
    },    

    startPhone2fa({ commit, dispatch }) {
      return axios.post('/api/verify/start', {
        phone_number: '+447739181046',
        via: 'sms'
      })
    },

    verifyPhone2fa({ commit, dispatch }, token) {
      return axios.post('/api/verify/verify', {
        token,
        phone_number: '+447739181046',
      }).then((response) => {
        dispatch('me')
      })
    },

    me ({ commit, getters }) {
      return axios.get('/api/user').then((response) => {
        commit('SET_AUTHENTICATED', true)
        commit('SET_USER', response.data)
      }).catch(() => {
        commit('SET_AUTHENTICATED', false)
        commit('SET_USER', null)
      })
    }

  }
}



export default auth