<template>
  <div class="row">
    <div class="col-12 col-md-6 rounded p-3 p-sm-5 text-light">
      <h1 class="main-title mb-3 mb-sm-5">Please tell us here if you have any assets</h1>
      <p>Assets can be things like:</p>
      <ul class="check-list mb-3 mb-sm-5">
        <li class="check-list__item"><i class="fas fa-check-circle"></i>Shares</li>
        <li class="check-list__item"><i class="fas fa-check-circle"></i>Investments</li>
        <li class="check-list__item"><i class="fas fa-check-circle"></i>Savings</li>
        <li class="check-list__item"><i class="fas fa-check-circle"></i>Property</li>
        <li class="check-list__item"><i class="fas fa-check-circle"></i>Vehicles you own</li>
      </ul>
    </div>
    <div class="col-12 col-md-6 p-3 p-sm-5 rounded bg-light">

      <h2 class="step-panel__title d-flex justify-content-between">Your assets<strong v-if="data.has_assets">{{ total | currency }}</strong></h2>

      <b-form-group label="Do you have any other assets?">
        <b-form-radio-group
          v-model="hasAssets"
          name="assets"
          :value='false'
          buttons
          button-variant="clear"
          :options="{
              yes: {text: 'Yes', value: true},
              no: {text: 'No', value: false},
          }"
        />
      </b-form-group>

      <div v-if='hasAssets'>

        <div class="card my-4" v-for="(asset, index) in $v.data.assets.$each.$iter" :key="index">
          <div class="card-body">

            <div class="row d-flex justify-content-center align-items-center">

              <form-wrapper :validator="asset" class="col step-form">

                <div class="form-row">

                
                  <form-group
                    name="type"
                    labelId="type"
                    class="step-form__item col"
                  >
                    
                    <b-form-select
                      class="form-control step-form__input"
                      :value="data['assets'][index]['type']"
                      @input="setData(index, 'type', $event)"
                      @select="asset.type.$touch()"
                      :state="validateState(asset.type)"
                      :options="types"
                    ></b-form-select>

                  </form-group>



                  <!-- Conditional inputs -->



                  <form-group
                    v-if="data['assets'][index]['type'] == 'investments' || data['assets'][index]['type'] == 'shares'"
                    label="Name"
                    labelId="name"
                    name="name"
                    class="step-form__item d-flex align-items-center col-12"
                    :messages="{
                      required: 'Please enter the name'
                    }"
                  >
                    
                    <b-form-input                    
                      class="form-control step-form__input"
                      :value="data['assets'][index]['name']"
                      @change="setData(index, 'name', $event)"
                    ></b-form-input>

                  </form-group>




                  <!-- VEHICLES -->
                  <form-group
                    v-if="data['assets'][index]['type'] == 'vehicle'"
                    label="Registration"
                    labelId="registration"
                    name="registration"
                    class="step-form__item d-flex align-items-center col-12"
                    :messages="{
                      required: 'Please enter the vehicle registration'
                    }"
                  >
                    
                    <b-form-input                    
                      class="form-control step-form__input"                      
                      placeholder="e.g. AA1 1AA"
                      :value="data['assets'][index]['registration']"
                      @change="setData(index, 'registration', $event)"
                    ></b-form-input>

                  </form-group>

                  <form-group
                    v-if="data['assets'][index]['type'] == 'vehicle'"
                    label="Mileage (miles)"
                    labelId="mileage"
                    name="mileage"
                    class="step-form__item d-flex align-items-center col-12"
                    :messages="{
                      required: 'Please enter the vehicle mileage'
                    }"
                  >
                    
                    <b-form-input                    
                      class="form-control step-form__input"                      
                      placeholder="e.g. 35,000"
                      :value="data['assets'][index]['mileage']"
                      @change="setData(index, 'mileage', $event)"
                    ></b-form-input>

                  </form-group>

                  <form-group
                    v-if="data['assets'][index]['type'] == 'vehicle'"
                    label="Make"
                    labelId="make"
                    name="make"
                    class="step-form__item d-flex align-items-center col-12"
                    :messages="{
                      required: 'Please enter the vehicle make'
                    }"
                  >
                    
                    <b-form-input                    
                      class="form-control step-form__input"                      
                      placeholder="e.g. Ford"
                      :value="data['assets'][index]['mileage']"
                      @change="setData(index, 'mileage', $event)"
                    ></b-form-input>

                  </form-group>
                  <!-- END VEHICLES -->


                  <!-- PROPERTIES -->
                  <form-group
                    v-if="data['assets'][index]['type'] == 'property'"
                    label="Property type"
                    labelId="property_type"
                    name="property_type"
                    class="step-form__item d-flex align-items-center col-12"
                    :messages="{
                      required: 'Please select the property type'
                    }"
                  >
                    
                    <b-form-select
                      class="form-control step-form__input"                      
                      :value="data['assets'][index]['property_type']"
                      @change="setData(index, 'property_type', $event)"
                      :options="propertyTypes"
                    ></b-form-select>

                  </form-group>
                  <!-- END PROPERTIES -->


                  <!-- end conditional inputs -->





                  <form-group
                    label="Asset value"
                    name="value"
                    labelId="value"
                    class="step-form__item d-flex align-items-center col-12"
                    :messages="{
                      required: 'Please enter an asset value',
                      minValue: 'Please enter an asset value'
                    }"
                  >
                    
                    <currency-input                    
                      class="form-control step-form__input"
                      :value="data['assets'][index]['value']"
                      @input="setData(index, 'value', $event)"
                      @blur="asset.value.$touch()"
                      :state="validateState(asset.value)"
                    ></currency-input>

                  </form-group>

                  <div class="col text-right">
                    <a href="javascript:void(0)" class="text-decoration-none" @click="removeAsset(key)"><i class="far fa-trash-alt error"></i> Delete asset</a>
                  </div> 

                </div>



              </form-wrapper>

              

                           

            </div>

          </div>
        </div>

        
        <div class="mt-4">
          <p><a @click="addAsset" href="javascript:void(0)" size="sm" class="text-decoration-none"><i class="far fa-plus"></i> {{ addMoreButtonText }}</a></p>
        </div>
        

      </div>

      <navigation class="mt-4" v-bind:disableNext="$v.$invalid"></navigation>
      
    </div>
  </div>
</template>

<script>

  import Navigation from './Navigation'

  import { mapGetters, mapMutations, mapState } from 'vuex'    

  import { validationMixin } from "vuelidate"
  import { helpers, required, email, minLength, minValue } from 'vuelidate/lib/validators'

  class Asset {
    constructor() {
      this.type = null
      this.value = 0
    }
  }

  export default {

    components: { Navigation },

    computed: {

      addMoreButtonText() {
        switch(this.data.assets.length) {
          case 0:
            return 'Add your first asset'
          case 1:
            return 'Add another asset'
          case 2:                    
          default:
            return 'Add more assets'
        }
      },

      ...mapState({
        data: state => state.plan.data,
      }),

      hasAssets: {
        get () {
          return this.$store.state.plan.data.has_assets == 1
        },
        set (value) {
          this.$store.commit('SET_HAS_ASSETS', value)
        }
      },

      total: function() {
        return this.$store.getters.totalAssets
        // sum all 'expenses' values in the vuex store
        //return Object.values(this.data['income']).reduce((p, c) => p + c)
      },            
    },

    watch: {
      hasAssets: function(newValue, oldValue) {
        if(newValue === true && this.data.assets.length == 0) {
          this.addAsset()
        }
      }
    },

    methods: {
      addAsset() {
        this.$store.commit('ADD_ASSET')
      },
      removeAsset(index) {
        this.$store.commit('REMOVE_ASSET', {index})
        if(this.data.assets.length == 0) {
          // automatically set hasAssets = 0
          this.hasAssets = false
        }
      },                        // todo - remove the DRY across components...
      setData: function(index, key, value) {
        // TODO - Why is $event passing the value???? Figure that out plz
        this.$store.commit('SET_INDEXED_ARRAY_DATA', {
          section: 'assets',
          index,
          key,
          value
        })
      },
      validateState(field) {
        const { $dirty, $error } = field;
        return $dirty ? !$error : null;
      }
    },


    data () {
     return {
      types: [
        { value: null, text: 'Select type of asset'},
        { value: 'shares', text: 'Shares'},
        { value: 'investments', text: 'Investments'},
        { value: 'savings', text: 'Savings'},
        { value: 'property', text: 'Property'},
        { value: 'vehicle', text: 'Vehicle'},
      ],
      propertyTypes: [
        { value: null, text: "Select an option"},
        { value: 'house', text: "House"},
        { value: 'flat-or-apartment', text: "Flat or apartment"},
        { value: 'land', text: "Land"},
        { value: 'other', text: "Other"},
      ],
      assetType: 'asset2',
      assetValue: 0,
      // todo link with vuex store
      amount: 0
     }
    },

    validations() {



      if(this.hasAssets) {
        return {
          data: {
            assets: {
              //required,
              //minLength: minLength(1),
              $each: {
                type: {
                  //required
                },
                value: {
                  //required,
                  //minValue: minValue(1)
                }
              }
            }
          }
        }
      } else {
        return true
      }
    }

  }
</script>