<template>
  <div class="row">
    <div class="col rounded p-md-5 text-light">
      <h1 class="main-title mb-3 mb-sm-5">Congratulations - Your profile has been created</h1>
      <p class="mb-3 mb-sm-5">This is your dashboard. In order to help we need to get some information from you such as:</p>
      <ul class="check-list mb-3 mb-sm-5">
        <li class="check-list__item"><i class="fas fa-check-circle"></i> Your income</li>
        <li class="check-list__item"><i class="fas fa-check-circle"></i> Your outgoings</li>
        <li class="check-list__item"><i class="fas fa-check-circle"></i> Any assets you have</li>
        <li class="check-list__item"><i class="fas fa-check-circle"></i> What the total of your debt is</li>
      </ul>
      <p>One you have shared this information we can tell you what help is available to you and help you begin writing off problem debt. Let’s get started by telling us about your income.</p>

      
    </div>


    <div class="col p-3 p-sm-5 rounded bg-light">

      <h2 class="step-panel__title d-flex justify-content-between">Your monthly income<strong>{{ total | currency }}</strong></h2>
      
      <FinanceInput
        :items="items"
        section="income"
      />
      <navigation></navigation>
      
    </div>
  </div>
</template>

<script>


  import Navigation from './Navigation'
  import FinanceInput from '../common/FinanceInput'

  // Set the default values for the Expenses form
  // todo - import this from a seperate file, form-defaults.js
  const defaults = {
    earnings: {
      title: 'Earnings',
      inputs: {
        salary: 'Salary or wages (net)',
        partnerSalary: 'Partner salary or wages (net)',
        other: 'Other income'
      }
    },
    benefits: {
      title: 'Benefits & Tax Credits',
      inputs: {
        universalCredit:'Universal Credit',
        jobseekersAllowanceIncomeBased :'Jobseeker\'s Allowance (income based)',
        jobseekersAllowanceContributionBased :'Jobseeker\'s Allowance (contribution based)',
        incomeSupport:'Income Support',
        workingTaxCredit :'Working Tax Credit',
        childTaxCredit :'Child Tax Credit',
        childBenefit :'Child Benefit',
        employmentAndSupportAllowanceOrStatutorySickPay:'Employment and Support Allowance or Statutory Sick Pay',
        disabilityBenefits :'Disability Benefits',
        carersAllowance:'Carer\'s Allowance',
        localHousingAllowanceHousingBenefit:'Local Housing Allowance / Housing Benefit',
        councilTaxSupport:'Council Tax Support',
      }
    },
    pensions: {
      title: 'Pensions',
      inputs: {
        statePensions: "State pensions",
        privateOrWorkPensions: "Private or work pensions",
        pensionCredit: "Pension credit",
      }
    },
    other: {
      title: 'Other income',
      inputs: {
        maintenanceOrChildSupport: "Maintenance or child support",
        boardersOrLodgers: "Boarders or lodgers",
        nonDependantsContributions : "Non-dependants' contributions",
        studentLoansAndGrants: "Student loans and grants",
      }
    }
  }

  export default {

    //components: { FinanceInputs, Navigation },
    components: { Navigation, FinanceInput },

    computed: {

      items: function() {
        // todo - merge this with any non-default values held on db
        // (infer title from the key)
        return defaults
      },

      // todo: swap this out for ...mapGetters
      total: function() {
        return this.$store.getters.totalIncome
        // sum all 'expenses' values in the vuex store
        //return Object.values(this.data['income']).reduce((p, c) => p + c)
      }

    }


  }
</script>