<template>
  <div class="row">
    <div class="col-12 col-md-6 py-3 py-md-5 text-light">
      <h1 class="main-title mb-2 mb-md-5">Welcome to CreditFix</h1>


      <p>Do you already have an account? <router-link to="signin">Click here</router-link> to sign in</p>

      <p class="text-center text-md-left">Our easy-to-use debt advice tool can help you to:</p>
      <ul class="check-list">
        <li class="check-list__item">
          <i class="fas fa-check-circle"></i> Build a financial profile and budget online
        </li>
        <li class="check-list__item">
          <i class="fas fa-check-circle"></i> Get an accurate snapshot of your financial health
        </li>
        <li class="check-list__item">
          <i class="fas fa-check-circle"></i> Compare the best solutions for getting out of debt
        </li>
        <li class="check-list__item">
          <i class="fas fa-check-circle"></i> Connect with trusted debt advisors who can help
        </li>
      </ul>

      <br/><br/><br/>

      <h3>Test functionality</h3>
      <p><button @click="populate">Populate test data</button></p>

    </div>
    <form-wrapper :validator="$v.data" class="col-12 col-md-6 p-3 p-md-5 rounded bg-light step-form">
      <div v-if="section == 1">
        <h2 class="step-panel__title">Let's start building your profile!</h2>

        <form-group
          class="step-form__item"
          label="Full name"
          name="name"
          :messages="{
            required: 'Enter your full name',
            minLength: 'Please enter your full name'
          }"
        >
          <b-form-input
            class="form-control step-form__input"
            type="text"
            name="name"
            id="name"
            v-model="data.name"
            @blur="$v.data.name.$touch()"
            :state="validateState('name')"
          />
        </form-group>

        <form-group
          class="step-form__item"
          label="Email Address"
          name="email"
          :messages="{
            email: 'Please provide a valid email address',
            required: 'Enter your email address'
          }"
        >
          <b-form-input
            class="form-control step-form__input"
            type="text"
            name="email"
            id="email"
            v-model="data.email"
            @blur="$v.data.email.$touch()"
            :state="validateState('email')"
          />
        </form-group>

        <form-group
          class="step-form__item"
          label="Date of birth"
          name="dob"
          :messages="{
            maxValue: 'Please enter your date of birth',
            minLength: 'Please enter your full name'
          }"
        >
          <dropdown-datepicker
            dropdownClass="form-control step-form__input col-3"
            v-model="data.dob"
            display-format="dmy"
            v-bind:required="true"
            :defaultDate="null"
            :maxYear="2020"
            :minYear="1900"
            @blur="$v.data.dob.$touch()"
            :state="validateState('dob')"
          ></dropdown-datepicker>

          <!--<b-form-input
            class="form-control step-form__input col-4"
            type="date"
            name="dob"
            id="dob"            
            v-model="data.dob"
            @blur="$v.data.name.$touch()"
            :state="validateState('dob')"
            aria-describedby="dob-feedback"
          />-->

          <!--<input class="form-control step-form__input" type="date" dob="dateOfBirth" id="password" v-model="">-->
        </form-group>

        <hr>

        <!-- validating child components https://github.com/vuelidate/vuelidate/issues/333 -->
        <form-group
          name="address"
          :messages="{
            isAddressValid: 'Please enter a valid address'
          }"
        >

          <Address
            :validator="$v"
            name="address"
            @updateAddress="addressHandler"
            ref="address"
          ></Address>

        </form-group>

        <hr>

        <form-group
          class="step-form__item"
          label="U.K. mobile phone number"
          name="phone_number"
          :messages="{
            required: 'Please enter a UK mobile phone number'
          }"
        >
          <b-form-input
            class="form-control step-form__input col-6"
            type="text"
            name="phone_number"
            id="phone_number"
            v-model="data.phone_number"
            @blur="$v.data.phone_number.$touch()"
            :state="validateState('phone_number')"
          />

          <p class="pt-2">
            <small>You will receive an SMS code and be asked to enter this code into the next page. This is to verify the details you are submitting.</small>
          </p>
        </form-group>

        <!--<form-group
          class="step-form__item" label="Conformation code">
          <p>How would you like to receive the confirmation code?</p>
          <b-form-select
            class="form-control step-form__input col-4"
            type="text"
            name="how"
            id="how"
            @input="setData('receive_code', $event)"
            @blur="$v.data.receive_code.$touch()"
            :state="validateState('receive_code')"
            :options="confirmationTypes"
          ></b-form-select>
        </form-group>-->

        <button :disabled="$v.$invalid" @click="section = 2" class="btn btn-primary">Next Step</button>

        <!--<button
          type="submit"
          @click="register"
          class="btn btn-primary"
        >Register Test</button>-->
      </div>

      <div v-if="section == 2">
        <div class="row justify-content-center">
          <div class="rounded bg-light d-flex flex-column col-md-6 m-auto align-items-center">
            <h1 class="text-center">Thank you.</h1>
            <p class="text-center mb-5">Please create a password to complete your profile</p>

            <form-group label="New Password" class="step-form__item">
              <b-form-input
                class="form-control step-form__input"
                type="password"
                name="password"
                id="password"
                data-lpignore="true"
                v-model="data.password"
              ></b-form-input>

              <password
                :strength-meter-only="true"
                :secureLength="secureLength"
                placeholder="Create Password"
                @score="setScore"
                v-model="data.password"
              />
            </form-group>

            <ul class="check-list check-list--validation mb-3">
              <li class="check-list__item">
                <i class="far" :class="passLengthClass"></i> Be at least {{ secureLength }} characters long
              </li>
              <li class="check-list__item">
                <i class="far" :class="passStrengthClass"></i> Be strong enough
              </li>
            </ul>

            <p class="text-center">
              <small>By submitting your details you are agreeing to our terms and conditions and privacy policy.</small>
            </p>

            <b-button
              @click="section = 1"
              class="btn btn-primary mt-4"
            >Go Back</b-button>

            <b-button
              type="submit"
              class="btn btn-primary"
              @click="register"
              :disabled="!isPasswordStrong"
            >Complete profile</b-button>

          </div>
        </div>
      </div>
    </form-wrapper>

  </div>
</template>

<script>
//import axios from 'axios'
import axios from "axios";
import { mapActions, mapState } from "vuex";

import {
  helpers,
  required,
  email,
  minLength,
  maxValue
} from "vuelidate/lib/validators";

import Address from "../components/common/Address";
import { isAddressValid } from "@/validators";
import Password from "vue-password-strength-meter";
import DropdownDatepicker from "vue-dropdown-datepicker";


export default {
  name: "Register",

  components: {
    Address,
    DropdownDatepicker,
    Password
  },

  computed: {
    passLengthClass: function() {
      return {
        "fa-check": this.data.password.length >= this.secureLength,
        "fa-times error": this.data.password.length < this.secureLength
      };
    },
    passStrengthClass: function() {
      return {
        "fa-check": this.strength >= 2,
        "fa-times error": this.strength < 2
      };
    },
    isPasswordStrong: function() {
      return this.strength >= 2 && this.data.password.length >= this.secureLength
    }
  },

  data() {
    return {
      section: 1,
      strength: 0,
      secureLength: 8,
      confirmationTypes: [
        { value: null, text: "Select" },
        { value: "sms", text: "Text message" },
        { value: "call", text: "Phone call" }
      ],
      isAddressValid: false,
      data: {
        email: null,
        dob: null,
        address: {},
        name: null,
        phone: null,
        password: ""
      }
    };
  },

  methods: {
    addressHandler(data) {
      this.data.address = data
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.data[name];
      return $dirty ? !$error : null;
    },
    setScore: function(score) {
      this.strength = score;
    },
    populate: function() {
      this.$refs.address.form.address = {
        line_1: "123 Main Street",
        line_2: "Streetville",
        postcode: "AA1 1AA"
      }
      this.$refs.address.enterManually = true
      this.data = {
        email: "test"+Math.ceil(Math.random()*100000)+"@email.com",
        dob: '1990/01/01',
        name: "Testy McTestface",
        phone_number: "07777123123",
        password: '',
      }
    },
    async register() {
      this.data['password_confirmation'] = this.data.password
      await axios.post('/register', this.data).then(
        (response) => {
          this.$store.dispatch('auth/signIn', {
            email: this.data.email,
            password: this.data.password
          }).then(
            (loginResponse) => {
              this.$toasted.success("Thanks for registering")
              this.$router.push({name: 'registerSMS'})
            },
            (loginError) => {
              this.$toasted.error(loginError.response.data.message)
            }
          )
        },
        (error) => {
          this.$toasted.error(error.response.data.message)
        }
      )
    }
  },

  mounted: function() {
    //https://stackoverflow.com/questions/31917889/is-it-possible-to-trigger-events-using-vue-js
    //var elem = this.$els.passwordBtn
    //elem.click()
  },

  validations: {
    data: {
      name: {
        required,
        minLength: minLength(4)
      },
      dob: {
        required,
        maxValue: value => value < new Date().toISOString()
      },
      email: {
        required,
        email
      },
      phone_number: {
        required
      },
      address: {
        isAddressValid
      }
    }
  }
};
</script>


<style scoped>
.password-box {
  min-height: 120px;
}
</style>