<template>
  <div class="row">
    
    <div
      v-for="(result, index) in results" :key="result.id"
      class="col-12 card mb-3"
    >

      <b>Plan #{{ index + 1 }}</b>

      <div class="d-flex flex-row flex-wrap">


        
        <div
          class="card m-2 p-2 text-center flex"
          v-for="(title, key) in printFields"
          :key="key"
          style="min-width: 150px"
        >
          <h4>{{ title }}</h4>
          <span>{{ result.input[key] }}</span>
        </div>

      </div>

      <div class="d-flex flex-row">
        <div class="card bg-secondary text-white m-2 p-2 text-center" v-for="(props, key) in result.output" :key="key">
          <h4>{{ props.key }}</h4><span v-if="props.advise">Advise</span><span v-else>Don't advise</span>
        </div>
      </div>

    </div>


    <b-button @click="load">Reload</b-button>

  </div>
</template>

<script>

  import axios from 'axios'

  export default {

    name: 'PlanTests',

    data() {
      return {
        results: {},
        printFields: {
          total_income: "Income",
          total_outgoings: "Outgoings",
          total_assets: "Assets",
          total_cash: "Cash",
          total_vehicles: "Vehicles",
          disposable_income: "Disposable",
          total_debts: "Debts",
          owns_property: "Homeowner?",
          is_scotland_based: "Scotland?",
          count_creditors: "Creditors",
          affects_employment: "Job risk",
          legal_action_pending: "CCJ",

        }
      }
    },

    methods: {
      load() {
        axios.get('/api/plantest').then((data) => {
          console.log(data)
          this.results = data
        })
      }
    },

    mounted() {
      this.load()
    }

  }
</script>