import store from '../store'

export default class Results {

	constructor () {

		this.data = {
			iva: {
				title: 'IVA',
				component: 'IVA',
				fullTitle: 'Individual Voluntary Agreement',
				description: 'An IVA is a legally binding agreement between you and your creditors that must be administered by a licensed insolvency practitioner',
				img: 'shutterstock_1674151537.jpg',
				link: ''
			},
			das: {
				title: 'DAS',
				component: 'DAS',
				fullTitle: 'Debt Arrangement Scheme',
				description: 'A Debt Arrangement Scheme will allow you to pay off debts over an extended amount of time.',
				img: 'shutterstock_1718299927.jpg',
				link: ''
			},
			dro: {
				title: 'DRO',
				component: 'DRO',
				fullTitle: 'DRO Title',
				description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
				img: 'shutterstock_1718299927.jpg',
				link: ''
			},			
			'debt-collection': {
				title: 'Debt Collection',				
				component: 'DebtCollection',
				fullTitle: 'Debt Collection Scheme',
				description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
				img: 'shutterstock_1718299927.jpg',
				link: ''
			},						
			'debt-management': {
				title: 'Debt Management',
				component: 'DebtManagement',
				fullTitle: 'Debt Management Scheme',
				description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
				img: 'shutterstock_1718299927.jpg',
				link: ''
			},

			'bankruptcy': {
				title: 'Bankruptcy',
				component: 'Bankruptcy',
				fullTitle: 'Bankruptcy',
				description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
				img: 'shutterstock_1718299927.jpg',
				link: ''
			},
			'consolidation': {
				title: 'consolidation',
				component: 'Consolidation',
				fullTitle: 'consolidation',
				description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
				img: 'shutterstock_1718299927.jpg',
				link: ''
			},
			'trust-deed': {
				title: 'trust deed',
				component: 'TrustDeed',
				fullTitle: 'trust deed',
				description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
				img: 'shutterstock_1718299927.jpg',
				link: ''
			},
			'sequestration': {
				title: 'Sequestration',
				component: 'Sequestration',
				fullTitle: 'Sequestration',
				description: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.',
				img: 'shutterstock_1718299927.jpg',
				link: ''
			}
		}
		
	}

}