<template>
  <div v-if="displayProgressBar">
    <b-progress
      :value="currentPage + 1"
      :max="totalPages + 1"
      striped
      variant="success"
    ></b-progress>
  </div>
  <div v-else class="btn-toolbar pt-4" role="toolbar" aria-label="Toolbar with button groups">
    

    <!--<button v-if="currentPage() != 0" class="btn btn-secondary mr-1" @click="navigatePrevious" :disabled="isFirst()">
      Go Back
    </button>-->

    <button
      class="btn btn-secondary"
      :class="{ loading }"
      :disabled="disableNext"
      @click="navigateNext"
    >
      {{ nextButtonText() }}
      <b-spinner
        v-if="loading"
        class="ml-2"
        small
        label="Spinning"
      ></b-spinner>
    </button>

  </div>

</template>

<script>

  let pageList = [
    'income',
    'fixed-outgoings',
    'flexible-outgoings',
    'personal-details',
    'assets',
    'debts'
  ]

  export default {

    watch: {            
      //currentPage: function(val) {
        //console.log(this.pageList[this.currentPage]);
        
      //}
    },

    computed: {
      currentPage() {
        return this.pageList.indexOf(this.$route.name)
      },
      totalPages() {
        return this.pageList.length
      },      
    },

    props: {
      disableNext: Boolean,
      displayProgressBar: Boolean
    },

    data: function() {
      return {
        pageList,
        loading: false
      }
    },

    methods: {

      nextButtonText() {
        if(this.isLast()) {
          return "Finish"
        } else {
          return "Save & Continue"
        }
      },


      isFirst() {
        return this.$route.name === this.pageList[0]
      },

      isLast() {
        return this.$route.name === this.pageList[this.pageList.length - 1]
      },

      navigateNext() {        
        if(this.isLast()) {
          //this.$router.push({name: 'results'})
          this.gotoPage('results')
        } else {
          //this.$router.push({name: this.pageList[this.currentPage() + 1]})
          this.gotoPage(this.pageList[this.currentPage + 1])
        }                
      },

      navigatePrevious() {
        //this.$router.push({name: this.pageList[this.currentPage() - 1]})               
        this.gotoPage(this.pageList[this.currentPage - 1])
      },

      gotoPage(name) {
        this.loading = true
        this.$store.dispatch('savePlan').then(_ => {
          setTimeout(_ => {
            this.loading = false
            this.$router.push({ name }).catch((e) => {
              if (e.name !== 'NavigationDuplicated') {
                throw e;
              }
            });
          }, 200)  
        })
        
      },

      submit() {
        console.log('Submit');
      }
    }

    

  }
</script>