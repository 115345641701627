<template>
  <b-form-group
    :invalid-feedback="firstErrorMessage"
    :state="isValid"
    :label="label"
    :label-for="labelId"
  >
    <slot
      :attrs="{ state: isValid }"
      :listeners="{ input: () => preferredValidator.$touch() }"
    />
  </b-form-group>
</template>

<script>
  import { singleErrorExtractorMixin } from "vuelidate-error-extractor";

  export default {
    props: ['labelId'],
    name: "FormElement",
    extends: singleErrorExtractorMixin
  };
</script>
