import Vue from 'vue'
import VueRouter from 'vue-router'


import store from '../store'

import routes from './routes'

Vue.use(VueRouter)


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,  
  scrollBehavior (to, from, savedPosition) {    
    return { x: 0, y: 0 }
  }
})


/**
 * Manage routing cases
 * @param  {[type]} (to, from,         next [description]
 * @return {[type]}      [description]
 */
router.beforeEach((to, from, next) => {

  if(process.env.VUE_APP_NO_API) {
    // We're in design mode, don't process route logic
    next()
    return
  }

  /**
   * Don't allow results access if no plan loaded?
   * TBC
   */
  /*if(to.name == 'results' && !store.getters.getPlanId) {
    next({name: 'debts'})
  }*/

  /**
   * Figure out, based on the current user status
   * if they should be allowed access to this route. And if not, where they should
   * be redirect (see meta.redirectIf object in routes above)
   */
  // get the current user's status
  // This can be one of:
  //    guest - hasn't logged in yet
  //    unverifiedAuth - logged in, but hasn't completed 2FA
  //    verifiedAuth - logged in & completed 2FA
  const myStatus = store.getters['auth/status']
  // Check through this route's redirectIf to check if we need to redirect this user
  // based on their current login status
  let match = to.matched.find(x => (x.meta.redirectIf && x.meta.redirectIf[myStatus]))
  if(match) {
    next({name: match.meta.redirectIf[myStatus]})
  }

  
  /*if (!to.matched.find(x => x.meta.public)) { // marks some routes as public
      // if the user authenticated
      if (store.getters['auth/authenticated']) { // I declared a `getter` function in the store to check if the user is authenticated.
          // continue to the route
          next();
      } else {
          // redirect to login
          next({name: 'login'});
      }
  }*/
  
  /**
   * If entering the form but no plan is loaded, load the current user's
   * latest plan (this also creates one if it doesn't exist already)
   */
  if(to.matched.find(x => x.name === 'form') || to.name == 'results') {
    // We are going into a form page!
    if(!store.getters.getPlanId) {
      // Load a plan if one doesn't exist
      store.dispatch('loadPlan').then((response) => {
        if(to.name == 'results') {
          store.dispatch('getResults')
        }
      });
    }
  }

  // if the user is authenticated and the route is disabled for authenticated window
  /*if (store.getters['auth/authenticated'] && to.meta.disableIfLoggedIn) {
      // redirect to home
      next({name: 'home'});
  }*/

  /**
   * Basic 404 handling
   */
  if (!to.matched.length) {
    next('/notFound');
  } else {
    next();
  }
});




export default router
