<template>
  <div class="row">
    
    <div
      v-for="plan in user.plans" :key="plan.id"
      class="col-12 col-md-4"
    >

      <div class="card mb-4">
        <div class="card-body">
          <h2 class="card-title">Plan #{{ plan.id }}</h2>
          Income: {{ plan.total_income | currency }}<br/>
          Outgoings: {{ plan.total_outgoings | currency }}<br/>
          <b-button @click="loadPlan(plan.id)">Load plan</b-button>
          <b-button @click="deletePlan(plan.id)">Delete plan</b-button>
        </div>
      </div>
      
    </div>


    <div class="col-12 pt-5">
        <b-btn-toolbar>
          <b-button size="sm" @click="newPlan">New Plan</b-button>
        </b-btn-toolbar>
      
    </div>
  </div>

</template>
<script>

  import { mapState } from 'vuex'

  export default {

    computed: {
      ...mapState({
        user: state => state.auth.user,
      }),
    },

    methods: {
      loadPlan(id) {
        this.$store.dispatch('loadPlan', {id}).then(_ => {
          this.$router.push({name: 'form'})
        })
      },
      newPlan: function() {
        this.$store.dispatch('createPlan').then(_ => {
          this.$store.dispatch('auth/me')
        })
      },
      deletePlan: function(id) {
        this.$store.dispatch('deletePlan', {id}).then(_ => {
          this.$store.dispatch('auth/me')
        })
      }
    },

    mounted() {
      this.$store.dispatch('auth/me')
    },

    name: 'Account'

  }
</script>