<template>

  <form-wrapper :validator="$v" class="step-form -address">
    <!--<button @click="done">Init</button>-->



    <form-group
      class="form-group step-form__item"
      labelId="search"
      label="What's your address?"
      name="search"
      :class="{'off-screen': enterManually}"
    >

      <input
        class="form-control step-form__input"
        type="text"
        name="search"
        id="search"
        placeholder="Search for a city, town, address or postcode"
      >


      <b-card class="mt-3" v-if="addressFound">
        <div v-for="(part, key) in form.address" :key="key">{{ part }}</div>
      </b-card>

    </form-group>
    

    <a
      href="javascript:void(0)"
      class="text-decoration-none"
      @click="enterManually = !enterManually"
    >

      
      <span v-if="!enterManually">
        <i class="fas fa-pencil"></i> Or enter your address manually
      </span>

      <span v-else>
        <i class="fas fa-search"></i> Search for your address
      </span>

    </a>

    <div class="pt-3" :class="{'off-screen': !enterManually}">

      <form-group
        class="form-group step-form__item"
        labelId="test"
        name="line_1"
      >

        <input
          class="form-control step-form__input"
          type="text"
          name="line_1"
          placeholder="Address line 1"
          id="line_1"
          @blur="$v.form.address.line_1.$touch()"
          :state="false"
          v-model="form.address.line_1"
        >

      </form-group>
      
      <form-group
        class="form-group step-form__item"
        labelId="test"
        name="line_2"
      >

        <input
          class="form-control step-form__input"
          type="text"
          name="line_2"
          placeholder="Address line 2"
          id="line_2"
          v-model="form.address.line_2">
      </form-group>
      
      <form-group
        class="form-group step-form__item"
        labelId="test"
        name="line_3"
      >

        <input
          class="form-control step-form__input"
          type="text"
          name="line_3"
          placeholder="Address line 3"
          id="line_3"
          v-model="form.address.line_3"
        >
      </form-group>
      
      <form-group
        class="form-group step-form__item"
        labelId="test"
        name="line_4"
      >

        <input
          class="form-control step-form__input"
          type="text"
          name="line_4"
          placeholder="Address line 4"
          id="line_4"
          v-model="form.address.line_4" 
        >

      </form-group>        

      <form-group class="form-group step-form__item">
                  
        <input
          class="form-control step-form__input col-6"
          type="text"
          name="postcode"
          placeholder="Postcode"
          id="postcode"
          v-model="form.address.postcode"
        >

      </form-group>

    </div>
  </form-wrapper>
</template>

<script>

  import Vue from 'vue'

  import { helpers, required, email, minLength, maxValue } from 'vuelidate/lib/validators'  

  export default {
    name: "Address",

    computed: {
      valid() {
        return !this.$v.$invalid
      }
    },

    data () {
      return {
        enterManually: false,
        addressFound: false,
        fetchify: null,
        ccto: false,
        form: {
          address: {
            line_1: '',
            line_2: '',
            line_3: '',
            line_4: '',
            postcode: ''
          }
        }
      }
    },

    watch: {
      form: {
        handler(v) {
          this.$emit('updateAddress', v.address)
        },
        deep: true
      },
      valid: {
        handler(v) {
          this.$emit('valid', v)
        },
        deep: true
      }
    },

    methods: {
      doChange(a) {
        console.log('changed'+a)
      },
      done() {

        let _this = this

        // Temporary hack to get this running for demo
        // Verrry messy. But seems to work. Interval is cancelled when
        // navigating away from this component.
        // Issue is that this just doesn't seem to want to initialise until
        // some n number of ms after the page has loaded.
        this.ccto = setInterval(_ => {
          try {
            this.fetchify = new window.clickToAddress({
              accessToken: process.env.VUE_APP_FETCHIFY_API_KEY, // Replace this with your access token
              dom: {
                search:     'search', // 'search_field' is the name of the search box element
                line_1:     'line_1',
                line_2:     'line_2',
                //company:    'company',
                town:       'line_3',
                postcode:   'postcode',
                county:     'line_4',
                country:    'addr_country'
              },
              texts: {
                // Placeholder to be displayed in the search box
                default_placeholder: 'Search for a city, town, address or postcode',
              },
              countrySelector: false,
              domMode: 'name',
              onResultSelected: (c2a, elements, address) => {
                let ids = ['line_1', 'line_2', 'line_3', 'line_4', 'postcode']
                ids.forEach((el) => {
                    if(typeof elements[el] !== 'undefined') {
                      _this.form.address[el] = elements[el].value
                    } else {
                      _this.form.address[el] = ''
                    }
                  })
                this.addressFound = true
              }
            });
          } catch(e) {
            //
          }
        }, 1000);
      }
    },

    beforeDestroy() {
      // Clear out our horrible interval from done() when we
      // navigate away from this component
      clearInterval(this.ccto)
      // Fetchify checks for "Already intialisaed" by looking for a specific
      // element/ID in the page. Fool it by deleting the element
      // so that we can reinitialise when coming back to this page.
      var element = document.getElementById("cc_c2a")
      element.parentNode.removeChild(element)
      delete this.fetchify
    },

    created() {
      
    },

    mounted() {
      this.fetchify = null
      //load external fetchify API
      let script = document.createElement('script')
      //script.async = true
      script.setAttribute('src', '//cc-cdn.com/generic/scripts/v1/cc_c2a.min.js')
      document.head.appendChild(script)
      // Doesn't seem to work.....
      this.$nextTick(() => {
        this.done()
      })

    },

    validations: {
    
      form: {
        address: {
          line_1: {
            required
          },
          postcode: {
            required
          }
        }
      }
    }

  }
</script>