<template>
    <div class="row">
        <div class="col-12 col-sm rounded p-md-5 text-light">
            <h1 class="main-title mb-3 mb-sm-5">We’re nearly done - we just have a few more questions</h1>
            <p class="mb-3 mb-sm-5">We are now going to ask you about</p>
            <ul class="check-list mb-3 mb-sm-5">
              <li class="check-list__item"><i class="fas fa-check-circle"></i> Some personal details</li>
              <li class="check-list__item"><i class="fas fa-check-circle"></i> Your living situation</li>
            </ul>
        </div>
        <div class="col-12 col-sm rounded p-3 p-sm-5 bg-light">

            <h2 class="step-panel__title d-flex justify-content-between">Your personal details</h2>
            
            <b-form-group
                label="What is your employment status?"
                class="question"
            >
                <b-form-radio-group
                    id="jobs"
                    :checked="data['personal']['jobs']"
                    @input="setData('jobs', $event)"
                    :options="options.jobs"
                    buttons
                    button-variant="clear"
                    name="jobs"
                    ></b-form-radio-group>
            </b-form-group>

            <b-form-group
                label="What is your living situation?"                
                class="question"
            >
                <b-form-radio-group
                    id="living-situation"
                    :checked="data['personal']['livingSituation']"
                    @input="setData('livingSituation', $event)"
                    :options="options.livingSituation"
                    buttons
                    button-variant="clear"
                    name="living-situation"
                    ></b-form-radio-group>
            </b-form-group>

            <b-form-group
                label="How many dependents live with you?"                
                class="question"
            >
                <b-form-radio-group
                    id="dependents"
                    :checked="data['personal']['dependents']"
                    @input="setData('dependents', $event)"
                    :options="options.dependents"
                    buttons
                    button-variant="clear"
                    name="dependents"
                    ></b-form-radio-group>
            </b-form-group>


            <p>(The following questions have been added for testing purposes)</p>


            <b-form-group
                label="Employment at risk?"                
                class="question"
            >
                <b-form-radio-group
                    id="employment-risk"
                    :checked="data['personal']['employmentRisk']"
                    @input="setData('employmentRisk', $event)"
                    :options="options.employmentRisk"
                    buttons
                    button-variant="clear"
                    name="employment-risk"
                    ></b-form-radio-group>
            </b-form-group>

            <b-form-group
                label="CCJs?"                
                class="question"
            >
                <b-form-radio-group
                    id="legal-risk"
                    :checked="data['personal']['legalRisk']"
                    @input="setData('legalRisk', $event)"
                    :options="options.legalRisk"
                    buttons
                    button-variant="clear"
                    name="legal-risk"
                    ></b-form-radio-group>
            </b-form-group>          


            <b-form-group
                label="Postcode"
                class="question"
            >
                <b-form-input
                    class="form-control step-form__input"
                    id="postcode"
                    :value="data['postcode']"
                    @input="setData('postcode', $event)"
                    buttons
                    button-variant="clear"
                    name="postcode"
                    ></b-form-input>
            </b-form-group>  

            <navigation></navigation>
        </div>

        
    </div>
</template>

<script>

    import { mapState } from 'vuex'    

    import Navigation from './Navigation'

    export default {

        components: { Navigation },

        computed: {
            ...mapState({
                data: state => state.plan.data,
            }),
        },

        data() {
            return {
                gender: null,
                maritalStatus: null,
                options: {
                    jobs: [
                        { text: 'Full Time', value: 'full-time' },
                        { text: 'Part Time', value: 'part-time' },
                        { text: 'Retired', value: 'retired' },
                        { text: 'Carer', value: 'carer' },
                        { text: 'Self-employed', value: 'self-employed' },
                        { text: 'Unemployed', value: 'unemployed' },
                        { text: 'Student', value: 'student' },
                    ],
                    livingSituation: [
                        { text: 'Home owner', value: 'home-owner' },
                        { text: 'Mortgage', value: 'mortgage' },
                        { text: 'Renting', value: 'renting' },
                        { text: 'Living with parents', value: 'living-with-parents' },
                        { text: 'Other', value: 'other' },
                    ],
                    dependents: [
                       { text: '0', value: '0' },
                       { text: '1-2', value: '1-2' },
                       { text: '3-4', value: '3-4' },
                       { text: 'More than 4', value: '4+' },
                    ],
                    legalRisk: [
                        { text: 'Yes', value: true },
                        { text: 'No', value: false },
                    ],
                    employmentRisk: [
                        { text: 'Yes', value: true },
                        { text: 'No', value: false },
                    ]                    

                }
            }
        },

        methods: {
            // todo - remove the DRY across components...
            setData: function(key, value) {
                if(key == 'postcode') {
                    // special case, for testing
                    this.$store.commit('SET_POSTCODE', value)
                } else {
                    this.$store.commit('SET_DATA', {
                        section: 'personal',
                        key,
                        value
                    })    
                }                
            },
        }        
    }
</script>