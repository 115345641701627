<template>
  <div class="row">
    <div class="col py-5 text-light">
        <h1 class="main-title mb-5">Reset your password?</h1>
        <p>Enter your new password here. Once confirmed, you will be logged back in to your account</p>
        
        <p><router-link to="/signin">Click here</router-link> to sign in</p>
    </div>    
    <div class="col p-5 rounded bg-light">
      <form-wrapper :validator="$v.form" class="col p-5 rounded bg-light step-form">

        <b-form action="#" @submit.prevent="submit" >

          <form-group
            class="step-form__item"
            label="Your email"
            name="email"
          >

            <b-form-input
              class="form-control step-form__input"
              type="email"
              name="email"
              id="email"
              data-lpignore="true"
              v-model="form.email"
              :disabled="true"
            ></b-form-input>

          </form-group>



          <form-group
            class="step-form__item"
            label="Your new password"
            name="password"
            :messages="{
              required: 'Enter your password'
            }"
          >

            <b-form-input
              class="form-control step-form__input"
              type="password"
              name="password"
              id="password"
              data-lpignore="true"
              v-model="form.password"
            ></b-form-input>

            <password
              :strength-meter-only="true"
              :secureLength="secureLength"
              placeholder="Create Password"
              @score="setScore"
              v-model="form.password"
            />

          </form-group>


          <b-btn-toolbar>
            <button
              :disabled="$v.$invalid"
              class="btn btn-primary"
              :class="{ loading }"
              type="submit"
            >
              Reset password
              <b-spinner
                v-if="loading"
                class="ml-2"
                small
                label="Spinning"
              ></b-spinner>
            </button>
          </b-btn-toolbar>

        </b-form>
      </form-wrapper>

    </div>
  </div>
</template>

<script>
  //import axios from 'axios'
  import { mapActions } from 'vuex'
  import { validationMixin } from "vuelidate";
  import Password from "vue-password-strength-meter";
  import { helpers, required, email, minLength } from 'vuelidate/lib/validators'

  export default {
    name: 'Reset',

    components: {
      Password
    },

    mixins: [validationMixin],

    data () {
      return {
        strength: null,
        secureLength: 8,
        form: {
          token: this.$route.params.token,
          email: this.$route.query.email,
          password: ''
        },
        loading: false
      }
    },

    methods: {
      ...mapActions({
        sendPasswordReset: 'auth/sendPasswordReset',
        signIn: 'auth/signIn'
      }),

      setScore: function(score) {
        this.strength = score;
      },

      // Export to global mixins?
      validateState(name) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      },

      async submit () {
        this.$v.$touch()
        if(!this.$v.$invalid) {
          try {            
            this.loading = true
            await this.sendPasswordReset({
              email: this.form.email,
              token: this.form.token,
              password: this.form.password
            })
            await this.signIn({
              email: this.form.email,
              password: this.form.password
            })
            this.$toasted.success("Your password has been reset, you have been logged in")            
            this.$router.replace({ name: 'form' })
          } catch(error) {
            this.$toasted.error(error.response.data.errors.email)
          }
          this.loading = false
        } else {
          return false
        }
      }
    },

    validations: {
        
        form: {
            password: {
                required
            }
        }
    }

  }
</script>