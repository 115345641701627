<template>
  <div class="row" v-if="results"> 
    <div class="col-12">
     <h2 class="text-white">Recommended for you:</h2>
    </div>



    <div v-if="readMoreComponent" class="col-12 col-md-8">
      <div >
        <div class="product -vertical d-flex flex-column justify-content-between">
          <div class="product__info">



            <b-button class="mb-3" @click="setReadMore(false)">&laquo; Back to your results</b-button>

            <component v-bind:is="readMoreComponent"></component>
        
            

          </div>
        </div>

      </div>

      
    </div>

    <!--<div
      :class="{ hidden: readMoreComponent }"
    >-->

    <div class="mb-3 col-12 col-md-8 mt-4 mt-md-0" v-if="results && results.length == 0">
      <div class="d-flex align-items-stretch">
        <div class="product -vertical d-flex flex-column justify-content-between">
          <div class="product__info">
            <h3 class="product__title -large">Sorry</h3>
            <p>Based on the information provided, we haven't been able to find any advice for you currently, please request a callback using the form on the right if you would like more information</p>
          </div>
        </div>
      </div>
    </div>

    <ResultCard
      v-for="(result) in results.slice(0, 2)"
      :class="cardClass"
      :key="result.key"
      :result="result.key"
      layout="vertical"
      class="mb-3 col-12"
      @readMore="setReadMore"
    />

    <!-- Callback box -->
    <div class="mb-3 col-12 mt-4 mt-md-0" :class="cardClass">

      <div class="d-flex align-items-stretch">
        <div class="product -vertical d-flex flex-column justify-content-between">
          <div class="product__info">
            <b-form action="#" @submit.prevent="submit" >
              <h3 class="product__title -large">
                <i
                  class="fas fa-phone"
                  :class="{
                    'text-danger': !callbackRequested,
                    'text-success': callbackRequested
                  }"></i>
                Request a callback
              </h3>
              <div v-if="!callbackRequested">
                <p class="product__desc">We will call you on your mobile number (07777 123123) at a date and time that suits you</p>
                
                <form-group
                  class="step-form__item"
                  label="Select a date"
                >
                  <b-form-input
                    class="form-control step-form__input"
                    type="date"
                    v-model="selectedDate"
                  ></b-form-input>
                </form-group>
                
                <form-group
                  v-if="selectedDate"
                  label="Select a time"
                  class="time"
                >
                  <b-form-radio-group
                    id="times"
                    v-model="selectedTime"
                    :options="availableTimes"
                    buttons
                    button-variant="clear"
                    name="times"
                  ></b-form-radio-group>
                </form-group>
                
                <b-button
                  v-if="selectedDate && selectedTime"
                  @click="submit"
                  :class="{ loading: requestingCallback }"
                >
                  Request a callback

                  <b-spinner
                    v-if="requestingCallback"
                    class="ml-2"
                    small
                    label="Spinning"
                  ></b-spinner>

                </b-button>


              </div>
              <div v-else class="col-10 m-auto h-100 text-center d-flex flex-column justify-content-center align-items-center py-5">
                <h2>Thank you!</h2>
                <p>Your callback has been requested for<br/>{{ selectedDate }} at {{ selectedTime }}</p>
                <p><b-button @click='callbackRequested = false'>Change this date/time</b-button></p>
              </div>
            </b-form>
          </div>
        </div>
      </div>

      <div v-if="readMoreComponent" class="mt-4 product -vertical d-flex flex-column justify-content-between">
        <div class="product__info">
          <h2>Other advice</h2>
          <ul>
            <li
              v-for="(result) in results"
              :key="result.key"
            >
              <a href="#" @click="readMoreComponent = result.component">
                {{ result.key }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    
    <!-- The rest of the cards -->
    <ResultCard
      v-for="(result) in results.slice(2)"
      :class="cardClass"
      :key="result.key"
      :result="result.key"
      layout="vertical"
      class="mb-3 col-12"
      @readMore="setReadMore"
    />


    <div class="col-12 mt-4">
      <div class="step-panel">
        <div class="row">
          <div class="col-12">
            <h2 class="step-panel__title mb-5">Need to report any changes?</h2>
          </div>
        </div>
        <div class="row">
            
          <div class="col-md-3">
            <div class="step-card">
              <h3 class="step-card__heading">Your income</h3>
              <div class="step-card__value">{{ income | currency }}</div>
              <div class="row align-items-center">
                <div class="col-md-8">
                  <p class="step-card__change">Do you need to report a change in your income?</p>
                </div>
                <div class="col-md-4">
                  <div class="step-card__edit">
                    <router-link href='#' :to="{name: 'income'}">Edit</router-link>
                    &nbsp;
                    <i class="fal fa-edit"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="col-md-3">
            <div class="step-card">
              <h3 class="step-card__heading">Your fixed outgoings</h3>
              <div class="step-card__value">{{ fixed | currency }}</div>
              <div class="row align-items-center">
                <div class="col-md-8">
                  <p class="step-card__change">Do you need to report a change in your fixed outgoings?</p>
                </div>
                <div class="col-md-4">
                  <div class="step-card__edit">
                    <router-link href='#' :to="{name: 'fixed-outgoings'}">Edit</router-link>
                    &nbsp;
                    <i class="fal fa-edit"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="col-md-3">
            <div class="step-card">
              <h3 class="step-card__heading">Your flexible outgoings</h3>
              <div class="step-card__value">{{ flexible | currency }}</div>
              <div class="row align-items-center">
                <div class="col-md-8">
                  <p class="step-card__change">Do you need to report a change in your flexible outgoings?</p>
                </div>
                <div class="col-md-4">
                  <div class="step-card__edit">
                    <router-link href='#' :to="{name: 'flexible-outgoings'}">Edit</router-link>
                    &nbsp;
                    <i class="fal fa-edit"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="step-card">
              <h3 class="step-card__heading">Your assets</h3>
              <div class="step-card__value">
                <span v-if="assets">{{ assets | currency }}</span>
                <span v-else>No assets</span>
              </div>
              <div class="row align-items-center">
                <div class="col-md-8">
                  <p class="step-card__change">Do you need to report a change in your assets?</p>
                </div>
                <div class="col-md-4">
                  <div class="step-card__edit">
                    <router-link href='#' :to="{name: 'assets'}">Edit</router-link>
                    &nbsp;
                    <i class="fal fa-edit"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="step-card">
              <h3 class="step-card__heading">Your personal circumstances</h3>
              <div class="step-card__value"></div>
              <div class="row align-items-center">
                <div class="col-md-8">
                  <p class="step-card__change">If your personal circumstances have changed then please let us know</p>
                </div>
                <div class="col-md-4">
                  <div class="step-card__edit">
                    <router-link href='#' :to="{name: 'personal-details'}">Edit</router-link>
                    &nbsp;
                    <i class="fal fa-edit"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="step-card">
              <h3 class="step-card__heading">Your debts</h3>
              <div class="step-card__value">{{ debts | currency }}</div>
              <div class="row align-items-center">
                <div class="col-md-8">
                  <p class="step-card__change">If you have missed out any debts, please add them here</p>
                </div>
                <div class="col-md-4">
                  <div class="step-card__edit">
                    <router-link href='#' :to="{name: 'debts'}">Edit</router-link>
                    &nbsp;
                    <i class="fal fa-edit"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>                  

        </div>
      </div>
    </div>

    <!--<button @click="refresh">Reload results</button>-->

  </div>
</template>

<script>

    import ResultCard from '../components/common/ResultCard'

    import { mapGetters, mapMutations, mapState } from 'vuex'        

    import DAS from '../components/results/DAS'
    import DRO from '../components/results/DRO'
    import IVA from '../components/results/IVA'
    import Bankruptcy from '../components/results/Bankruptcy'
    import Consolidation from '../components/results/Consolidation'
    import DebtCollection from '../components/results/DebtCollection'
    import DebtManagement from '../components/results/DebtManagement'
    import Sequestration from '../components/results/Sequestration'
    import TrustDeed from '../components/results/TrustDeed'

    export default {

        name: 'Results',

        components: {
          DAS, DRO, IVA,
          Bankruptcy,
          Consolidation,
          DebtCollection,
          DebtManagement,
          Sequestration,
          TrustDeed,
          ResultCard
        },

        computed: {
          ...mapState({
              data: state => state.plan.data,
              results: function(state) {
                if(!this.readMoreComponent) {
                  console.log('test')
                  return state.plan.results
                } else {
                  return []
                }
              }
          }),
          ...mapGetters({
            income: 'totalIncome',
            flexible: 'totalFlexible',
            fixed: 'totalFixed',
            assets: 'totalAssets',
            vehicles: 'totalVehicles',
            debts: 'totalDebts',
          }),
          cardClass() {
            //console.log(this.$store.getters.results)
            if(this.$store.getters.countResults === 1 && !this.readMoreComponent) {
              return "col-md-6 col-sm-6"
            } else {
              return "col-md-4 col-sm-6"
            }
          }
        },

        created: function() {
          // Fetch results from serverside, if not already cached
          if(this.$store.state.plan.refreshResults) {
            this.$store.commit('SET_REFRESH_RESULTS', false)
            this.refresh()
          }
        },

        methods: {
          refresh: function() {
            this.$store.dispatch('getResults')
          },
          setReadMore: function(key) {
            this.readMoreComponent = key
            window.scrollTo(0, 0); 
          },          
          submit() {
            this.requestingCallback = true
            this.callbackRequested = false
            setTimeout(_ => {
              this.requestingCallback = false
              this.callbackRequested = true
            }, 1500)
          }
        },

        data: function() {
          return {
            readMore: false,
            availableTimes: {
              1100: "11:00",
              1130: "11:30",
              1200: "12:00",
              1230: "12:30",
            },
            readMoreComponent: false,
            requestingCallback: false,
            callbackRequested: false,
            selectedDate: null,
            selectedTime: null
          }
        }
    }

</script>