import App from './App.vue'
import axios from 'axios'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import router from './router'
import store from './store'
import Toasted from 'vue-toasted';
import Vue from 'vue'
import VueCurrencyInput from 'vue-currency-input'
import VCalendar from 'v-calendar'
import VuePlaceAutocomplete from 'vue-place-autocomplete';
import Vuelidate from 'vuelidate'
import VuelidateErrorExtractor, { templates } from "vuelidate-error-extractor";  


// Classes
import Plan from './classes/Plan'


Vue.use(BootstrapVue)
Vue.use(VueCurrencyInput, {
  globalOptions: { currency: 'GBP' }
})
Vue.use(Toasted, {
  action : {
      text : 'ok',
      onClick : (e, toastObject) => {
          toastObject.goAway(0);
      }
  },
  position: 'bottom-left',
  duration: 4000

})
Vue.use(Vuelidate)
Vue.use(VuelidateErrorExtractor, { 
  template: templates.bootstrap
})

// For design purposes, allow the frontend to run without
// the API
Vue.prototype.$noApi = process.env.VUE_APP_NO_API

//Vue.config.productionTip = false

// refer to https://codesandbox.io/s/2pww743mrr?file=/src/components/FormSummary.vue:0-393
import FormGroup from "./components/common/FormGroup.vue";
import FormSummary from "./components/common/FormSummary.vue";

Vue.component("FormGroup", FormGroup);
Vue.component("FormSummary", FormSummary);
Vue.component("formWrapper", templates.FormWrapper);

axios.defaults.withCredentials = process.env.VUE_APP_WITH_CREDS
axios.defaults.baseURL = process.env.VUE_APP_API_URL

if(!process.env.VUE_APP_API_URL) {
  throw new Error("VUE_APP_API_URL undefined in .env")
}

// Axois Interceptors (global)
axios.interceptors.response.use(function (response) {
  // Rewrite response so we're not having to deal with e.g. response.data.data.id
  // and instead can use response.data.id
  //return response;
  return response.data;
}, function (error) {
  console.log(error.response)
  if(!error.status) {
    //alert("A network error ocurred (please see browser console)");
  }
  // Do something with response error
  if(error.response) {
    // Todo - toast?
    
    //console.log()
  }
  //return error.response.data
  throw error;
  //return Promise.reject(error);
});

// Fetch session before display.. todo - implement Sanctum API
store.dispatch('auth/me').then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app')
})

Vue.filter('currency', function (value) {
  return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value)
})

Vue.mixin({
  methods: {
    humanizeCamel: str => {
      let result = str.replace( /([A-Z])/g, " $1" );
      return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
    }
  }
})