<template>
  <div id="app" class="main-content">

    <header>
      <div class="container">
        <b-navbar toggleable="md">
          
          
          <b-navbar-brand>
              <router-link to="/">
                <img src="@/assets/img/cf-logo.svg" style="width: 150px" >
              </router-link>
          </b-navbar-brand>

          <b-navbar-toggle target="navbar-toggle-collapse"></b-navbar-toggle>

          <b-collapse id="navbar-toggle-collapse" class="main-nav" is-nav>
            <b-navbar-nav>
              
              <b-nav-item v-if="!authenticated" class="main-nav__item" to="/register">
                Register
              </b-nav-item>

              <b-nav-item class="main-nav__item" to="/form">
                Dashboard
              </b-nav-item>

              <b-nav-item v-if="isPlanLoaded" class="main-nav__item" to="/results">
                Results
              </b-nav-item>              
                
              <b-nav-text v-if="authenticated" class="main-nav__item">
                
                <span class="user">{{ user.name }}</span>
                &nbsp;
                <span v-if="verified"><i class="fas fa-check text-success"></i></span>
                <span v-else><i class="fas fa-times text-danger"></i></span>

              </b-nav-text>

              <b-nav-item v-if="authenticated" class="main-nav__item mr-0" @click="logout">
                Log out
              </b-nav-item>

              <b-nav-item v-if="authenticated" class="main-nav__item mr-0" :to="{name: 'account'}">
                Account
              </b-nav-item>

              <b-nav-item v-if="!authenticated" class="main-nav__item" to="/signin">
                Log in
              </b-nav-item>

            </b-navbar-nav>
          </b-collapse>

          
        </b-navbar>
      </div>
    </header>

    <div class="container">

      <div class="row">
        <div class="col py-4">
          <transition name="fade" mode="out-in">
            <router-view></router-view>
          </transition>
        </div>
        
      </div>

    </div>

    <div v-if="noApi">
      <b-nav-item class="main-nav__item" to="/register">Register</b-nav-item>
      <b-nav-item class="main-nav__item" to="/form">Form</b-nav-item>
      <b-nav-item class="main-nav__item" to="/signin">Login</b-nav-item>
      <b-nav-item class="main-nav__item" to="/results">Results</b-nav-item>
      <b-nav-item class="main-nav__item" to="/register/sms">SMS</b-nav-item>
    </div>

  </div>
</template>

<script>


  import { mapGetters, mapMutations, mapState } from 'vuex'

  export default {

    data() {
      return {
        noApi: process.env.VUE_APP_NO_API,
        expanded: true
      }
    },

    mounted: function() {
      // Load Font Awesome
      let script = document.createElement('script')
      script.setAttribute('src', '//kit.fontawesome.com/941777c0a4.js')
      document.head.appendChild(script)
    },

    methods: {
      logout: function() {
        this.$store.dispatch('auth/signOut').then(
          (response) => {
            this.$toasted.success("You've logged out")
          },
          (error) => {
            this.$toasted.error(error.response.data.message)
          }
        )
      },
      setStandalone: function(e) {
        console.log(e)
        this.$store.commit('SET_STANDALONE', e)
      },
      toastTest: function() {
        this.$toasted.error('This is a popup test!')
      }
    },

    computed: {
      ...mapState({
        standalone: state => state.standaloneMode
      }),
      expenses () {
        return this.$store.getters.totalExpenses
      },
      income () {
        return this.$store.getters.totalFixed
      },
      plan () {
        return this.$store.state.plan.data
      },
      isPlanLoaded() {
        return this.$store.state.plan.id ? true : false
      },
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user',
        verified: 'auth/verified',
      })
    }

  }

</script>

<style lang="scss">
@import 'assets/style.scss';
</style>
