<template>
  <div class="row">
    <div class="col rounded p-5 text-light">
      <h2 class="display-4">Vehicles</h2>
      <p>Lorem ipsum, dolor sit, amet consectetur adipisicing elit. Odit placeat rerum consectetur, repellat, officiis repudiandae libero porro a iste nulla. Nobis modi nulla unde quisquam, possimus iure laborum. Enim, laborum!</p>
      <ul>
        <li>Lorem ipsum dolor sit amet, consectetuer adipiscing</li>
        <li>Lorem ipsum dolor sit amet, consectetuer adipiscing</li>
        <li>Lorem ipsum dolor sit amet, consectetuer adipiscing</li>
        <li>Lorem ipsum dolor sit amet, consectetuer adipiscing</li>
        <li>Lorem ipsum dolor sit amet, consectetuer adipiscing</li>
      </ul>
    </div>
    <div class="col p-5 rounded bg-light">

      <h2 class="step-panel__title d-flex justify-content-between">Your vehicles<strong>{{ total | currency }}</strong></h2>

      <b-form-group label="Do you have any vehicles?">
        <b-form-radio-group
          v-model="hasVehicles"
          name="assets"
          :value='false'
          buttons
          button-variant="clear"
          :options="{
              yes: {text: 'Yes', value: true},
              no: {text: 'No', value: false},
          }"
        />
      </b-form-group>

      <transition name="fade-slow" mode="out-in">

        <div v-if="hasVehicles">

          <div class="card my-4" v-for="(vehicle, index) in $v.data.vehicles.$each.$iter" :key="index">
            <form-wrapper :validator="vehicle" class="card-body step-form">

              <h2>Vehicle {{ index + 1 }} <a href="javascript:void(0)" @click="removeVehicle()">(Delete <i class="far fa-1x fa-times error"></i>)</a></h2>

              <div class="row">

                <div class="col-6">
                  <form-group
                    label="Vehicle type"
                    name="type"
                    class="step-form__item"
                  >
                    
                    <b-form-select
                      class="form-control step-form__input"
                      :value="data['vehicles'][index]['type']"
                      @input="setData(index, 'type', $event)"
                      @blur="vehicle.type.$touch()"
                      :state="validateState(vehicle.type)"
                      :options="types"
                    ></b-form-select>
                    
                  </form-group>
                </div>
                
                <div class="col-6">
                  
                  <form-group
                    label="Make"
                    name="make"
                    class="step-form__item"
                  >
                   
                   <b-form-input
                      :value="data['vehicles'][index]['make']"
                      @blur="vehicle.make.$touch()"
                      :state="validateState(vehicle.make)"
                      class="form-control step-form__input"
                      @input="setData(index, 'make', $event)"
                    ></b-form-input>

                  </form-group>                            
                </div>

                <div class="col-4">
                  
                  <form-group
                    label="Mileage"
                    name="mileage"
                    class="step-form__item"
                  >

                    <b-form-input
                      type="number"
                      class="form-control step-form__input"
                      :value="data['vehicles'][index]['mileage']"
                      @blur="vehicle.mileage.$touch()"
                      :state="validateState(vehicle.mileage)"
                      @input="setData(index, 'mileage', $event)"
                    ></b-form-input>

                  </form-group>

                </div>

                <div class="col-4">

                  <form-group
                    label="Registration"
                    name="registration"
                    class="step-form__item"
                  >

                    <b-form-input
                      :value="data['vehicles'][index]['registration']"
                      @blur="vehicle.registration.$touch()"
                      class="form-control step-form__input"
                      :state="validateState(vehicle.registration)"
                      @input="setData(index, 'registration', $event)"
                    ></b-form-input>

                  </form-group>

                </div>

                <div class="col-4">
                  
                  <form-group
                    label="Value"
                    name="value"
                    class="step-form__item"
                  >

                    <currency-input
                      class="form-control step-form__input"
                      :value="data['vehicles'][index]['value']"
                      @blur="vehicle.value.$touch()"
                      :state="validateState(vehicle.value)"                                          
                      @input="setData(index, 'value', $event)"
                    ></currency-input>

                  </form-group>

                </div>


              <form-group class="col-12" label="Is this car specially adapted for disabled passengers?">
                <b-form-radio-group
                id="adapted"
                :value="data['vehicles'][index]['adapted']"
                @input="setData(index, 'adapted', $event)"
                :options="options.yn"
                @blur="vehicle.adapted.$touch()"
                :state="validateState(vehicle.adapted)"                                
                buttons
                button-variant="clear"
                name="adapted"
                ></b-form-radio-group>
              </form-group>


              </div>

              <div class="row">

                <form-group class="col-6" label="Is this car financed?">
                  <b-form-radio-group
                  id="financed"
                  :value="data['vehicles'][index]['financed']"
                  @input="setData(index, 'financed', $event)"
                  :options="options.yn"
                  @blur="vehicle.financed.$touch()"
                  :state="validateState(vehicle.financed)"
                  buttons
                  button-variant="clear"
                  name="financed"
                  ></b-form-radio-group>
                </form-group>

                <form-group
                  class="col-6 step-form__item"
                  v-if="data['vehicles'][index]['financed'] == 1"
                  label="Finance value"
                >

                  <currency-input
                    class="form-control step-form__input"
                    :value="data['vehicles'][index]['financeValue']"
                    @input="setData(index, 'financeValue', $event)"
                    @blur="vehicle.financeValue.$touch()"
                    :state="validateState(vehicle.financeValue)"
                  >
                  </currency-input>

                </form-group>

              </div>

            </form-wrapper>

          </div>

          <a @click="addVehicle" href="javascript:void(0)" size="sm">{{ addMoreButtonText }}</a>

        </div>

      </transition>

      <navigation class="mt-4" v-bind:disableNext="$v.$invalid"></navigation>

    </div>

      
  </div>
</template>

<script>

  import Navigation from './Navigation'

  import { mapGetters, mapMutations, mapState } from 'vuex'       

  import { validationMixin } from "vuelidate"
  import { helpers, required, email, minLength, maxLength, minValue, maxValue } from 'vuelidate/lib/validators'

  const UKreg = helpers.regex('alpha', /^[a-zA-Z]*$/)

  export default {

    components: { Navigation },

    computed: {

      addMoreButtonText() {
        switch(this.data.vehicles.length) {
          case 0:
            return '+ Add your first vehicle'
          case 1:
            return '+ Add another vehicle'
          case 2:                    
          default:
            return '+ Add more vehicles'
        }
      },

      ...mapState({
        data: state => state.plan.data,
      }),

      ...mapMutations({
        // todo - IMPORTANT! Figure out how to tie this in...
        update: 'UPDATE_VEHICLE'
      }),

      hasVehicles: {
        get () {
          return this.$store.state.plan.data.has_vehicles == 1
        },
        set (value) {
          this.$store.commit('SET_HAS_VEHICLES', value)
        }
      },

      total: function() {
        return this.$store.getters.totalVehicles
        // sum all 'expenses' values in the vuex store
        //return Object.values(this.data['income']).reduce((p, c) => p + c)
      },            
    },

    methods: {
      addVehicle() {
        this.$store.commit('ADD_VEHICLE')
      },
      removeVehicle(index) {
        this.$store.commit('REMOVE_VEHICLE', {index})
        if(this.data.vehicles.length == 0) {
          this.hasVehicles = false
        }
      },
      // todo - remove the DRY across components...
      setData: function(index, key, value) {
        // TODO - Why is $event passing the value???? Figure that out plz
        this.$store.commit('SET_INDEXED_ARRAY_DATA', {
          section: 'vehicles',
          index,
          key,
          value
        })
      },
      validateState(field) {
        const { $dirty, $error } = field;
        return $dirty ? !$error : null;
      }
    },

    mounted() {
      this.$v.$touch()
    },


    data () {
      return {
      types: [
        { value: null, text: ' - Select an option - '},
        { value: 'car', text: 'Car'},
        { value: 'van', text: 'Van'},
      ],
      assetType: 'asset2',
      assetValue: 0,
      own: null,
      financed: 0,
      financeValue: 123,
      // todo link with vuex store
      amount: 0,
      options: {
        yn: [
          { text: 'Yes', value: 1 },
          { text: 'No', value: 0 }
        ]
      }
      }
    },

    validations() {
      if(this.hasVehicles) {
        return {
          data: {
            vehicles: {
              required,
              minLength: minLength(1),
              $each: {
                type: {
                  required
                },
                make: {
                  required,
                },
                registration: {
                  required,
                  minLength: minLength(3),
                  maxLength: maxLength(10),
                },
                mileage: {
                  minValue: minValue(1),
                  maxValue: maxValue(1000000),
                  required
                },
                value: {
                  minValue: minValue(1),
                  required
                },
                adapted: {
                  required,
                },
                financed: {
                  required
                },
                financeValue: {
                  required
                }
              }
            }
          }
        }
      } else {
        return true
      }
    }
  }
</script>