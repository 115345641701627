<template>
  <div class="row">

    <div v-if="planId" class="col col-12">
      <div class="row">
        <Navigation
          class="col-lg-6 m-auto pb-4"
          :displayProgressBar="true"
        />
      </div>
      <transition name="fade" mode="out-in">
        <router-view></router-view>        
      </transition>
    </div>

    <div v-else class="col col-12 text-light">
        <p>Please click here to proceed <button @click="newPlan">Continue</button></p>
    </div>

  </div>
</template>

<script>

    import router from '../router'
    import Navigation from '../components/steps/Navigation'

    export default {
        name: 'Form',

        components: {
          Navigation
        },

        data: function() {
            return {
                item: 'Testing',
                loadPlanId: 1,
            }
        },

        methods: {
          /*clearPlan: function() {
            this.$store.dispatch('clearPlan')
          },
          
          loadPlan: function() {
            this.$store.dispatch('loadPlan', {id: this.loadPlanId})
          },

          savePlan: function() {
            this.$store.dispatch('savePlan')
          },*/

          newPlan: function() {
            this.$store.dispatch('createPlan')
          }
        },

        computed: {
            plan () {
                return this.$store.state.plan.data
            },
            planId () {
                return this.$store.state.plan.id
            }
        }
    }

</script>