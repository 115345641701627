<template>
  <div class="row">
    <div class="col p-5 rounded bg-light">
      <h1>Out of the red</h1>
      <p>To get started click <router-link :to="{name: 'register'}">here</router-link></p>

    </div>
  </div>
</template>

<script>
  export default {
  name: 'Home',
  components: {
    //
  }
  }
</script>
