<template>
  <div class="row">
    <div class="col rounded p-md-5 text-light">
      <h1 class="main-title mb-3 mb-sm-5">Now, let's calculate your flexible outgoings</h1>
      <p class="mb-3 mb-sm-5">Flexible outgoings are generally things like</p>
      <ul class="check-list mb-3 mb-sm-5">
        <li class="check-list__item"><i class="fas fa-check-circle"></i> Internet</li>
        <li class="check-list__item"><i class="fas fa-check-circle"></i> Hairdressing costs</li>
        <li class="check-list__item"><i class="fas fa-check-circle"></i> Hobbies and leisure</li>
      </ul>
    </div>
    <div class="col p-3 p-sm-5 rounded bg-light">
      
      <h2 class="step-panel__title d-flex justify-content-between">Flexible outgoings<strong>{{ total | currency }}</strong></h2>

      <FinanceInput
         :items="items"
        section="flexible_outgoings"
      />
      <navigation></navigation>
      
    </div>
  </div>
</template>

<script>

  import Navigation from './Navigation'
  import FinanceInput from '../common/FinanceInput'

  
  const defaults = {
    communicationsAndLeisure: {
      title: 'Communications & leisure',
      inputs: {
        homePhone: 'Home phone',
        mobilePhone: 'Mobile phone',
        hobbies: 'Hobbies, leisure, sport etc.',
        newspapers: 'Newspapers',
        gifts: 'Gifts',
        pocketMoney: 'Pocket money',
        Other: 'Other',
      }
    },
    foodAndHousekeeping: {
      title: 'Food & housekeeping',
      inputs: {
        groceries: 'Groceries (e.g. food, pet food, non-alcoholic drinks, cleaning)',
        babyItems: 'Nappies and baby items',
        schoolMeals: 'School meals and meals at work',
        laundry: 'Laundry and dry cleaning',
        alcohol: 'Alcohol',
        smoking: 'Smoking products',
        petBills: 'Vet bills & pet insurance',
        houseRepair: 'House repairs and maintenance',
        other: 'Other costs',
      }
    },
    personalCosts: {
      title: 'Personal costs',
      inputs: {
        clothing: 'Clothing and footwear',
        hairdressing: 'Hairdressing',
        toiletries: 'Toiletries',
        other: 'Other costs',
      }
    }
  }

  export default {

    components: { Navigation, FinanceInput },

    computed: {
      items: function() {
        // todo - merge this with any non-default values held on db
        // (infer title from the key)
        return defaults
      },

      total: function() {
        // sum all 'expenses' values in the vuex store
        // Todo - check if this value exists in user meta
        return this.$store.getters.totalFlexible
      },
    }

  }
</script>