<template>
  <div class="row">
    <div class="col-12 col-sm rounded p-md-5 text-light">
      <h1 class="main-title mb-3 mb-sm-5">Great, now let's calculate your fixed outgoings</h1>
      
      <p class="mb-3 mb-sm-5">Fixed outgoings are things like:</p>
      <ul class="check-list mb-3 mb-sm-5">
        <li class="check-list__item"><i class="fas fa-check-circle"></i> Rent, mortgage etc</li>
        <li class="check-list__item"><i class="fas fa-check-circle"></i> Utility bills (gas, electricity)</li>
        <li class="check-list__item"><i class="fas fa-check-circle"></i> Other essential costs</li>
      </ul>
      <p>Capturing this information lets us know how your monthly outgoings weighs up against your monthly income</p>

    </div>
    <div class="col-12 col-sm p-3 p-md-5 rounded bg-light">
      
      <h2 class="step-panel__title d-flex justify-content-between">Fixed outgoings<strong>{{ total | currency }}</strong></h2>


      <FinanceInput
        :items="items"
        section="fixed_outgoings"
      />
      <navigation></navigation>
      
    </div>
  </div>
</template>

<script>

  import Navigation from './Navigation'
  import FinanceInput from '../common/FinanceInput'

// Todo - pull these in from API model?
  const defaults = {

    homeAndContents: {
      title: "Home and contents",
      inputs: {
        rent: "Rent",
        groundRentServiceChargesFactorFeesIfYouLiveInScotland: "Ground rent & service charges (factor fees if you live in Scotland)",
        mortgage: "Mortgage",
        mortgageEndowment: "Mortgage endowment",
        councilTaxRatesIncludingWaterChargeIfYouLiveInScotlandAndRatesInNi: "Council tax/rates (including water charge if you live in Scotland and rates in NI)",
        applianceFurnitureRentalIncludingApplianceAndFurnitureHpConditionalSaleAndSoOn: "Appliance & furniture rental (including appliance and furniture HP, conditional sale and so on)",
        tvLicence: "TV licence",
      },
    },
    utilities: {
      title: "Utilities",
      inputs: {
        gas: "Gas",
        electricity: "Electricity",
        otherCostsIncludingCoalOilCalorGasEtc: "Other costs (including coal, oil, calor gas etc.)",
      }
    },
    water: {
      title: "Water",
      inputs: {
        waterSupply: "Water supply",
        waterWaste: "Water waste",
      }
    },
    careAndHealthCosts: {
      title: "Care and health costs",
      inputs: {
        childcareCosts: "Childcare costs",
        adultCareCosts: "Adult-care costs",
        childMaintenanceOrChildSupport: "Child maintenance or child support",
        prescriptionsAndMedicines: "Prescriptions and medicines",
        dentistryAndOpticians: "Dentistry and opticians",
      }
    },
    transportAndTravel: {
      title: "Transport and travel",
      inputs: {
        publicTransportEGWorkSchoolShopping: "Public transport (e.g. work, school, shopping)",
        hirePurchaseOrConditionalSaleVehicle: "Hire Purchase or conditional sale vehicle",
        carInsurance: "Car insurance",
        roadTax: "Road tax",
        motAndOngoingMaintenance: "MOT and ongoing maintenance",
        breakdownCover: "Breakdown cover",
        fuelParkingAndTollRoadCharges: "Fuel, parking and toll road charges",
      }
    },
    schoolCosts: {
      title: "School costs",
      inputs: {
        schoolUniform: "School uniform",
        afterSchoolClubsAndSchoolTrips: "After-school clubs and school trips",
      }
    },
    pensionsAndInsurances: {
      title: "Pensions and insurances",
      inputs: {
        pensionPayments: "Pension payments",
        lifeInsurance: "Life insurance",
        mortgagePaymentProtectionInsurance: "Mortgage payment protection insurance",
        buildingsAndContentsInsurance: "Buildings and contents insurance",
        healthInsuranceMedicalOrAccidentOrDental: "Health insurance (medical or accident or dental)",
      }
    },
    professionalCosts: {
      title: "Professional costs",
      inputs: {
        professionalCourses: "Professional courses",
        unionFees: "Union fees",
        professionalFees: "Professional fees",
      }
    },
    otherEssentialCosts: {
      title: "Other essential costs",
      inputs: {
        other: "Other ",
      }
    }

  }


  export default {

    components: { Navigation, FinanceInput },

    computed: {

      items: function() {
        // todo - merge this with any non-default values held on db
        // (infer title from the key)
        return defaults
      },

      total: function() {
        // sum all 'expenses' values in the vuex store
        return this.$store.getters.totalFixed
      },

    },

  }
</script>