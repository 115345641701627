import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from '../router'
import routes from '../router/routes'
import auth from './auth'
import plan from './plan'

Vue.use(Vuex)

const store = new Vuex.Store({

  strict: process.env.NODE_ENV !== 'production',

  state () {
    return {
      standaloneMode: false
    }
  },

  mutations: {

    SET_STANDALONE(state, value) {
      state.standaloneMode = value
    },

  },

  modules: {
    auth,
    plan
  },
  
})

store.watch(
  (state) => state.auth.user,
  (val, oldVal) => {
    const current = router.history.current
    
    // DRY with router/index.js
    const myStatus = store.getters['auth/status']
    let match = current.matched.find(x => (x.meta.redirectIf && x.meta.redirectIf[myStatus]))
    if(match) {
      router.push({name: match.meta.redirectIf[myStatus]})
    }
  }
)



export default store