import axios from "axios";
import Vue from "vue";

export default {
  // todo: namespace this!!!!!!

  state() {
    return {
      data: [], // holds all the plan data
      results: [], // not used?
      refreshResults: true, // set to true whenever data updated,
      // signals that results need reloaded from server
      id: null // holds the current plan id
    };
  },

  mutations: {
    SET_PLAN(state, value) {
      state.data = value;
    },

    SET_PLAN_ID(state, value) {
      state.id = value;
    },

    SET_RESULTS(state, value) {
      state.results = value;
    },

    SET_REFRESH_RESULTS(state, value) {
      state.refreshResults = value
    },

    SET_POSTCODE(state, value) {
      state.data.postcode = value
    },

    // General data setting mutators
    SET_DATA(state, { section, key, value }) {
      if (typeof state.data[section] === "undefined") {
        state.data[section] = []
      }
      Vue.set(state.data[section], key, value)
    },

    // For e.g. assets, which are indexed arrays containing repeating data
    SET_INDEXED_ARRAY_DATA(state, { section, index, key, value }) {
      state.data[section][index][key] = value
    },

    // Assets
    SET_HAS_ASSETS(state, value) {
      state.data.has_assets = value
    },

    ADD_ASSET(state, value) {
      state.data.assets.push({
        type: null,
        value: 0
      });
    },

    REMOVE_ASSET(state, { index }) {
      state.data.assets.splice(index, 1);
    }, // vehicles section no longer used

    // Debts
    SET_HAS_DEBTS (state, value) {
      state.data.has_debts = value
    },

    ADD_DEBT(state, value) {
      state.data.debts.push({
        type: null,
        company: "",
        value: ""
      });
    },

    REMOVE_DEBT(state, { index }) {
      if (state.data.debts.length > 1) {
        state.data.debts.splice(index, 1);
      }
    }
  },

  getters: {
    plan: state => {
      //return new PlanData(state.data)
    },
    results: state => {
      return state.results
    },
    countResults: state => {
      return Object.keys(state.results).length
    },
    getHasAssets: state => {
      return state.data.has_assets
    },

    getPlanId: state => {
      return state.id
    },

    totalIncome: state => {
      if (Object.values(state.data.income).length) {
        return Object.values(state.data.income).reduce((p, c) => p + c);
      } else {
        return 0;
      }
    },
    totalFlexible: state => {
      if (Object.values(state.data.flexible_outgoings).length) {
        return Object.values(state.data.flexible_outgoings).reduce((p, c) => p + c);
      } else {
        return 0;
      }
    },
    totalFixed: state => {
      if (Object.values(state.data.fixed_outgoings).length) {
        return Object.values(state.data.fixed_outgoings).reduce((p, c) => p + c);
      } else {
        return 0;
      }
    },
    totalAssets: state => {
      if (state.data.has_assets) {
        return Object.values(state.data.assets).reduce((p, c) => p + parseFloat(c.value), 0);
      } else {
        return 0;
      }
    },
    totalVehicles: state => {
      return Object.values(state.data.vehicles).reduce((p, c) => p + parseFloat(c.value), 0);
    },
    totalDebts: state => {
      return Object.values(state.data.debts).reduce((p, c) => p + parseFloat(c.value), 0);
    }
  },

  actions: {
    /*setPlanId({ commit }, planId) {
      this.planId = planId
    },*/

    clearPlan({ commit }) {
      commit("SET_PLAN", false);
      commit("SET_PLAN_ID", false);
    },

    loadPlan({ commit, state }, plan) {
      return axios.get('/api/plans/latest').then((response) => {
        commit('SET_PLAN', response.data)
        commit('SET_PLAN_ID', response.data.id)
      }).catch((error) => {
        /*this.dispatch('createPlan').then(_ => {
          this.dispatch('loadPlan'); // looper?
        })*/
      })
    },

    createPlan({ commit, dispatch, state }) {
      // todo - look at using localStorage when not logged in/user hasn't
      // granted save yet
      // https://www.digitalocean.com/community/tutorials/vuejs-vuex-persist-state
      return axios.post('/api/plans').then((response) => {
        commit('SET_PLAN', response.data)
        commit('SET_PLAN_ID', response.data.id)
      }).catch(() => {
        dispatch('clearPlan');
      })
    },

    deletePlan({ commit, dispatch, state }, plan) {
      // todo - look at using localStorage when not logged in/user hasn't
      // granted save yet
      // https://www.digitalocean.com/community/tutorials/vuejs-vuex-persist-state
      return axios
        .delete("/api/plans/" + plan.id)
        .then(response => {
          if (state.id == plan.id) {
            dispatch("clearPlan");
          }
        })
        .catch(() => {
          //dispatch('clearPlan');
        });
    },

    savePlan({ commit, dispatch, state }) {
      // todo - look at using localStorage when not logged in/user hasn't
      // granted save yet
      // https://www.digitalocean.com/community/tutorials/vuejs-vuex-persist-state
      return axios
        .put("/api/plans/" + state.id, state.data)
        .then(response => {
          commit("SET_PLAN", response.data);
          commit("SET_PLAN_ID", response.data.id);
          commit("SET_REFRESH_RESULTS", true);
        })
        .catch(() => {
          dispatch("clearPlan");
        });
    },

    getResults({ commit, dispatch, state }) {
      return axios
        .get("/api/plans/" + state.id + "/results")
        .then(response => {
          commit("SET_RESULTS", response.data);
          //commit('SET_USER', response.data)
        })
        .catch(() => {
          //commit('SET_AUTHENTICATED', false)
          //commit('SET_USER', null)
        });
    }
  }
};
