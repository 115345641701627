<template>
  <div class="d-flex align-items-stretch" :class="cardClass">
    <div class="product d-flex justify-content-between" :class="layoutClass">
      <div class="product__info">
        <h3 class="product__title">{{ title }}</h3>
        <h4 class="product__fulltitle">{{ fullTitle }}</h4>
        <p class="product__desc">{{ description }}</p>
        <button
          class="btn"
          @click="$emit('readMore', result)"
        >Read more</button>
      </div>
      <div class="product__img-container">
        <img class="product__img" :src="require('@/assets/img/' + img)" />
      </div>
    </div>
  </div>
</template>

<script>

import Results from '../../classes/Results'

export default {
    
    name: 'card',

    props: {
      result: String,
      layout: String, // vertical or horizontal
      cardClass: String,
      readMore: [String, Boolean]
    },

    computed: {
      fullTitle() {
        return this.results.data[this.result].fullTitle
      },
      title() {
        return this.results.data[this.result].title
      },
      description() {
        return this.results.data[this.result].description
      },
      img() {
        return this.results.data[this.result].img
      },
      layoutClass() {
        if(this.layout == 'horizontal') {
          return 'flex-row'
        } else {
          return 'flex-column'
        }
      }
    },

    data: function() {
        return {
          item: 'Testing',
          results: {}
        }
    },

    created() {
      this.results = new Results
    }
}
</script>